import { useState, useMemo, memo } from 'react'
import { useSelector } from 'react-redux'
import { Typography } from '@mui/material'
import DataPage, {
  isNull,
  isEmpty,
  selectRecords,
  selectRecord,
  isDataAvailable,
  selectGeography
} from '../components/DataPage/DataPage'
import DataMap from '../components/DataMap/DataMap'
import { Row, Col } from '../components/Grid'
import { formatPropertyClass, getClassByCode } from '../utils/propertyClass'
import { titleCase } from '../utils/stringUtils'
import { ReactComponent as SearchedForIcon } from '../components/DataMap/Icons/searched-for-icon.svg'
import { ReactComponent as RelatedParcelIcon } from '../components/DataMap/Icons/related-parcel-icon.svg'
import { ReactComponent as ParcelIcon } from '../images/parcel-icon.svg'
import Overlay from '../components/DataMap/Overlays/Overlay'
import EvictionList from '../components/EvictionList'
import BuildingPermitList from '../components/BuildingPermitList'
import BuildingComplaintList from '../components/BuildingComplaintList'
import RAPPetitionList from '../components/RAPPetitionList'
import PageTitle from '../components/PageTitle'
import PropertyList from '../components/PropertyList'
import ForeclosureList from '../components/ForeclosureList'
import Legend from '../components/DataMap/Overlays/Legend'
import Accordion from '../components/Accordion'
import { useTranslation } from 'react-i18next'
import i18n from '../i18n'
import { renderOwnerLink, renderNeighborhoodLink } from '../utils/tableUtils'
import OwnershipNetwork from '../components/OwnershipNetwork'
import { useParams } from 'react-router-dom'
import { config } from '../config/config'
import { OwnerSectionHeader } from '../components/SectionHeaders'
import { formatMoney } from '../utils/stringUtils'
import InterpolateTranslation from '../components/InterpolateTranslation'


const iconStyle = { height: '45px', width: '45px', marginTop: '.75rem' }
const columnStyle = /** @type {const} */ ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})

/**
 * ParcelPage: presents content for a property parcel
*/
const Parcel = () => {
  /* Set up API data fields and selectors */
  const select = config.api.response.parcelPage.selectors
  const value = config.api.response.parcelPage.values

  /* Get the id path parameter from the URL bar */
  const params = useParams()
  const { id: parcel_id } = params ?? {}

  /* Set hooks to use Redux global state */
  const evictionSourceData = useSelector(selectRecord('about-evictions'))
  const assessorSourceData = useSelector(selectRecord('about-assessor'))
  const buildingPermitSourceData = useSelector(selectRecord('about-building-permits'))
  const buildingComplaintSourceData = useSelector(selectRecord('about-building-complaints'))
  const rapPetitionSourceData = useSelector(selectRecord('about-rap-petitions'))
  const geography = useSelector(selectGeography(`${parcel_id}-details`))

  /* String representing ID of currently selected network
   * If empty string, then portfolio endpoint will fetch data for first connected network
   * */
  const [activeNetwork, setActiveNetwork] = useState('')

  const { isLoggedIn } = useSelector(state => state.login)

  /* Set up the react-i18next translation engine */
  const { t, ready: translationReady } = useTranslation(
    'ParcelPage' /* i18next-parser needs string here */,
    { useSuspense: false, i18n }
  )

  /* Translation calls with t() must occur after translationReady is true */
  /* Conditional returns must take place after all hooks have been called */
  if (!translationReady) {
    return null
  }

  /* Dynamic text */
  const evictionSourceText =
    select.sourceName(evictionSourceData?.[geography]) +
    ' (as of ' + select.sourceDate(evictionSourceData?.[geography]) + ')'
  const assessorSourceText =
    select.sourceName(assessorSourceData?.[geography]) +
    ' (as of ' + select.sourceDate(assessorSourceData?.[geography]) + ')'
  const buildingPermitSourceText =
    select.sourceName(buildingPermitSourceData?.[geography]) +
    ' (as of ' + select.sourceDate(buildingPermitSourceData?.[geography]) + ')'
  const buildingComplaintSourceText =
    select.sourceName(buildingComplaintSourceData?.[geography]) +
    ' (as of ' +
    select.sourceDate(buildingComplaintSourceData?.[geography]) +
    ')'
  const rapPetitionSourceText =
    select.sourceName(rapPetitionSourceData?.[geography]) +
    ' (as of ' +
    select.sourceDate(rapPetitionSourceData?.[geography]) +
    ')'

  const ParcelMap = () => {
    /* Set hooks to use Redux global state */
    const details = useSelector(selectRecord(`${parcel_id}-details`))
    const portfolio = useSelector(selectRecords(`${parcel_id}-portfolio-${activeNetwork}`))

    /* Map data includes portfolio properties as related parcels 
       and the page's details parcel as the searched-for parcel.
       It adds a type property for downstream code to choose the icon */
    const mapData = useMemo(
      () => {
        const propertyList = isEmpty(portfolio)
          ? []
          : portfolio
            .filter(x => !isNull(x.lat))
            .map(x => ({ ...x, type: 'Related Parcel' }))
        const ownerProperty = {
          ...details,
          type: 'Parcel',
          addr: select.mainAddress(details),
        }
        let data = [...propertyList]
        if (!isNull(ownerProperty.lat)) data = data.concat(ownerProperty)
        return data
      },
      // eslint-disable-next-line
      [portfolio, details]
    )

    return (
      <DataMap
        markers={mapData}
        isDebugEnabled={false}
        overlays={[
          <Overlay position='bottomright'>
            <Legend
              title={t('Legend')}
              items={[
                { icon: <SearchedForIcon />, label: t('This Property') },
                { icon: <RelatedParcelIcon />, label: t('Related Properties') },
              ]}
            />
          </Overlay>
        ]}
        isTooltipPermanent={false}
        renderMarkerHover={marker => (
          <Typography variant='body1'>
            {titleCase(select.propertyAddress(marker)) ||
            <span style={{ opacity: '0.6' }}>Unknown</span>}
          </Typography>
        )}
      />
    )
  }

  const ParcelAccordionSection = () => {
    const details = useSelector(selectRecord(`${parcel_id}-details`))
    const countyGeography = geography === value.sanFrancisco
      ? value.sanFrancisco
      : (geography === value.oakland
        ? value.alameda
        : null)
    const isExtraFieldsEmpty = select.isExtraFieldsEmpty(details)

    return (
      <Accordion
        fields={[
          {
            title: t('APN'),
            expandable: true,
            detailText: t("APN is the assessor's parcel number - a number assigned to property parcels by the tax assessor of a particular jurisdiction for identification and record-keeping. The assigned number is unique within the particular jurisdiction"),
            value: select.parcelId(details)
          },
          {
            title: t('Year Built'),
            expandable: false,
            detailText: null,
            value: select.yearBuilt(details)
          },
          {
            title: t('Property Class Code'),
            expandable: true,
            detailText: t('Land Use Codes: This code is used to identify the legal use of a property as determined by a city or county.'),
            value: select.propertyType(details),
            tooltipText: String(
              getClassByCode(
                select.propertyType(details),
                countyGeography
              )['use_definition']
            )
          },
          {
            title: t('Type'),
            expandable: false,
            detailText: null,
            value: select.parcelType(details),
          },
          {
            title: t('Stories'),
            expandable: false,
            detailText: null,
            value: select.numStories(details)
          },
          {
            title: t('Rooms'),
            expandable: false,
            detailText: null,
            value: select.numRooms(details)
          },
          {
            title: t('Units'),
            expandable: false,
            detailText: null,
            value: select.numUnits(details)
          },
          {
            title: t('Beds'),
            expandable: false,
            detailText: null,
            value: select.numBeds(details)
          },
          {
            title: t('Baths'),
            expandable: false,
            detailText: null,
            value: select.numBaths(details)
          },
          {
            title: t('Construction Type'),
            expandable: false,
            detailText: null,
            value: select.constructionType(details)
          },
          {
            title: t('Owner Type'),
            expandable: true,
            detailText: t('Indicates whether property is owned by a company or an individual.'),
            value: select.ownerType(details)
          },
          {
            title: t('Land Value'),
            expandable: true,
            formatter: formatMoney,
            detailText: t('The value the assessor places on a property for property tax assessment purposes, for the land portion of the property only.'),
            value: select.assessedLandValue(details)
          },
          {
            title: t('Improvement Value'),
            expandable: true,
            formatter: formatMoney,
            detailText: t('The value the assessor places on a property for property tax assessment purposes, for the improvement portion of the property only (e.g. the structures on the land).'),
            value: select.assessedImprovementValue(details)
          },
          {
            title: t('Owner Occupied'),
            expandable: true,
            detailText: t('Indicates whether property is occupied by its owner'),
            value: !isExtraFieldsEmpty ? select.ownerOccupied(details) : null
          },
          {
            title: t('Last Year Tax Delinquent'),
            expandable: false,
            detailText: null,
            value: !isExtraFieldsEmpty ? select.taxDelinquentYear(details) : null
          },
          {
            title: t('Is Owner Trust?'),
            expandable: false,
            detailText: null,
            value: !isExtraFieldsEmpty ? select.ownerIsTrust(details) : null
          },
          {
            title: t('Is Owner a Business?'),
            expandable: false,
            detailText: null,
            value: !isExtraFieldsEmpty ? select.ownerIsBusiness(details) : null
          },
          {
            title: t('Foundation Type'),
            expandable: false,
            detailText: null,
            value: !isExtraFieldsEmpty ? select.foundationType(details) : null
          },
          {
            title: t('Number of Partial Baths'),
            expandable: false,
            detailText: null,
            value: !isExtraFieldsEmpty ? select.numBathsPartial(details) : null
          }
        ]}/>
    )
  }

  const PropertyDetails = () => {
    /* Set hooks to use Redux global state */
    const details = useSelector(selectRecord(`${parcel_id}-details`))
    const owners = useSelector(selectRecord(`${parcel_id}-owners`))
    const geography = useSelector(selectGeography(`${parcel_id}-details`))
    // TODO: refactor this to be agnostic of specific geographies (can rely on data in config.js)
    const countyGeography = geography === value.sanFrancisco
      ? value.sanFrancisco
      : (geography === value.oakland
        ? value.alameda
        : null)

    /* Wait for data to become available */
    if (isNull(details) || isNull(owners)) return null

    /* Dynamic text */
    const propertyType = formatPropertyClass(
      getClassByCode(select.propertyType(details), countyGeography)
    )

    const address = titleCase(select.mainAddress(details))
    const yearBuilt = select.yearBuilt(details)
    const neighborhood_w_city = select.neighborhood_w_city(details)
    const numUnits = select.numUnits(details)
    const rentControlCode = select.rentControlCode(details)
    const firstOwnerName = select.firstOwnerName(owners)
    const addressVariations = select.addressVariations(details).map(d => titleCase(d))
    const numExtraAddressVariations = select.numExtraAddrVariations(details)

    switch (numExtraAddressVariations) {
      case 0:
        break
      case 1:
        addressVariations.push('1 other variation')
        break
      default:
        addressVariations.push(`${numExtraAddressVariations} other variations`)
    }

    const addrText = addressVariations.reduce((string, d, i) => {
      const comma = i < addressVariations.length - 1 && addressVariations.length !== 2 ? ', ' : ''
      const and = i === addressVariations.length - 2 ? ' and ' : ''
      return string + d + comma + and
    }, '')

    return <>
      <Typography variant='body1' style={{ maxWidth: '70ch' }}>
        {t('{{address}} is a {{propertyType}}', {
          address: address,
          propertyType: propertyType
        })}
        {yearBuilt && (
          <>
            {t(' built in {{year}}', {
              year: String(yearBuilt),
            })}
          </>
        )}
        {neighborhood_w_city && (
          <>
            {t(' in the ')}
            {renderNeighborhoodLink(neighborhood_w_city)}
            {t(' neighborhood')}
          </>
        )}
        {t('. ')}
        {typeof numUnits === 'number' &&
          t('It has {{count}} units. ', {
            count: numUnits,
            context:
              numUnits === 0 ? 'zero' /* Add a translation for zero with suffix _zero */ : '',
          })}
        {firstOwnerName && t('It is currently owned by {{owner_name}}.', {
          owner_name: titleCase(firstOwnerName)
        })}
        {
          {
            2: t('Since it was built before 1979, it is likely rent controlled.'),
            1: t('It is not likely rent controlled.'),
            0: t('No rent control data is available.'),
          }[rentControlCode]
        }
      </Typography>
      {addressVariations.length > 0 && (
        <>
          <br />
          <Typography variant='body1'>
            {t('This property is also listed under {{addrText}}.', {
              addrText: addrText,
            })}
          </Typography>
        </>
      )}
    </>
  }

  const EvictionSection = () => {
    /* Set hooks to use Redux global state */
    const details = useSelector(selectRecord(`${parcel_id}-details`))
    const evictions = useSelector(selectRecords(`${parcel_id}-evictions`))

    /* Wait for data to become available */
    if (isEmpty(details) || isEmpty(evictions)) { return null }

    /* Dynamic text */
    const numUnits = select.numUnits(details)
    const address = select.mainAddress(details)

    const numEllisEvictions = evictions.filter(
      eviction => select.evictionType(eviction) === value.ellisActWithdrawal
    ).length
    const numOtherEvictions = evictions.length - numEllisEvictions

    return (
      <EvictionList
        slice={`${parcel_id}-evictions`}
        title={t('Eviction History')}
        subTitle={
          <>
            {t('At ')}
            {address}
            {t(' we have records of ', {
              context:
                typeof numUnits !== 'number' || numUnits === 0
                  ? 'zero' /* Add a translation for zero with suffix _zero */
                  : '',
            })}
            {numEllisEvictions > 0 &&
              t('{{count}} Ellis Act {{evictionText}}{{endLink}}', {
                count: numEllisEvictions,
                evictionText: numEllisEvictions > 1 ? 'evictions' : 'eviction',
                endLink: numOtherEvictions === 0 && !numUnits ? t('. ') : '',
              })}
            {numEllisEvictions > 0 &&
              numUnits > 0 &&
              t('{{startLink}} affecting the {{units}} units{{endLink}}', {
                startLink: numEllisEvictions > 1 ? ', each' : ',',
                units: numUnits,
                endLink: numOtherEvictions > 0 ? '' : t('. '),
              })}
            {numOtherEvictions > 0 &&
              t('{{startLink}}{{count}} {{otherText}}{{evictionText}}', {
                startLink: numEllisEvictions > 0 ? t(' and ') : '',
                count: numOtherEvictions,
                otherText: numEllisEvictions > 0 ? t('other ') : '',
                evictionText: numOtherEvictions > 1 ? t('evictions. ') : t('eviction. '),
                context:
                  typeof numUnits !== 'number' || numUnits === 0
                    ? 'zero' /* Add a translation for zero with suffix _zero */
                    : '',
              })}
            {t('Duplicate rows here represent evictions at different units at this property.')}
          </>
        }
        noDataMessage={t('No evictions recorded for this address.')}
        aboutData={{
          source: evictionSourceText,
          href: '/methodology',
          notes: geography === value.oakland
            ?
            t(`Eviction records data come from two general sources: 
            Alameda County Sheriff actions and City of Oakland eviction filing records;
            they generally only include the address of the eviction.
            Evictions were matched to parcel records and property ownership records.
            As a result, some evictions were matched to multiple or no properties, if an exact
            match could not be found. Some evictions also include names on the filing,
            which may be either property owners or lawyers involved in the case. For these,
            we have shown the name identified through property records, and the additional
            names are those found on the eviction filing itself.`)
            :
            t(`Eviction records generally only include the address of the eviction.
            Evictions were matched to parcel records and property ownership records.
            As a result, some evictions were matched to multiple or no properties, if an exact 
            match could not be found. Some evictions also include names on the filing,
            which may be either property owners or lawyers involved in the case. For these,
            we have shown the name identified through property records, and the additional
            names are those found on the eviction filing itself.`)
        }}
        paginationPageSize={5}
        showAddress={false}
        csvFileName={t('Eviction history for address {{address}}', { address: address })}
      />
    )
  }

  const BuildingPermitSection = () => {
    /* Set hooks to use Redux global state */
    const details = useSelector(selectRecord(`${parcel_id}-details`))
    const isBuildingPermitDataAvailable = useSelector(isDataAvailable(`${parcel_id}-building-permits`))

    if (isNull(details)) { return null }
    if (!isBuildingPermitDataAvailable) { return null }

    const address = titleCase(select.mainAddress(details))

    return (
      <BuildingPermitList
        slice={`${parcel_id}-building-permits`}
        title={t('Building Permit History')}
        noDataMessage={t('No building permits recorded for this address.')}
        aboutData={{
          source: buildingPermitSourceText,
          href: '/methodology',
          notes:
            t(`Building Permits data come from the city of Oakland and
            are filings for legal approval made by owners for changes
            to their properties.
            Building Permit records are matched to parcel records
            and property ownership records based on address at time
            of filing. As a result, some records were matched to
            multiple or no properties, and are not shown if an exact
            match could not be found.`),
        }}
        showLocation={false}
        showOwner
        paginationPageSize={5}
        csvFileName={t('Building permit history for address {{address}}', { address: address })}
      />
    )
  }

  const BuildingComplaintSection = () => {
    /* Set hooks to use Redux global state */
    const details = useSelector(selectRecord(`${parcel_id}-details`))
    const isBuildingComplaintDataAvailable = useSelector(isDataAvailable(`${parcel_id}-building-complaints`))

    if (isNull(details)) { return null }
    if (!isBuildingComplaintDataAvailable) { return null }

    const address = select.mainAddress(details)

    return (
      <BuildingComplaintList
        slice={`${parcel_id}-building-complaints`}
        title={t('Building Complaint History')}
        noDataMessage={t('No building complaints recorded for this address.')}
        aboutData={{
          source: buildingComplaintSourceText,
          href: '/methodology',
          notes:
            t(`Building Complaints data come from the city of Oakland and
            are claims made against property or owner behavior based on
            code enforcement rules.
            Building Complaints records are matched to parcel records
            and property ownership records based on address at time of
            filing. As a result, some records were matched to multiple
            or no properties, and are not shown if an exact match could
            not be found.`),
        }}
        showLocation={false}
        showOwner
        paginationPageSize={5}
        csvFileName={t('Building complaint history for address {{address}}', { address: address })}
      />
    )
  }

  const RAPPetitionSection = () => {
    /* Set hooks to use Redux global state */
    const details = useSelector(selectRecord(`${parcel_id}-details`))
    const isRAPPetitionDataAvailable = useSelector(isDataAvailable(`${parcel_id}-rap-petitions`))

    if (isNull(details)) { return null }
    if (!isRAPPetitionDataAvailable) { return null }

    const address = select.mainAddress(details)

    return (
      <RAPPetitionList
        slice={`${parcel_id}-rap-petitions`}
        title={t('Rent Adjustment Program (RAP) Petition History')}
        noDataMessage={t('No petitions recorded for this address.')}
        aboutData={{
          source: rapPetitionSourceText,
          href: '/methodology',
          notes:
            t(`RAP petition records are matched to parcel records and
            property ownership records based on address at time of filing.
            As a result, some records were matched to multiple or no
            properties, and are not shown if an exact match could not be found.`)
        }}
        showLocation={false}
        paginationPageSize={5}
        csvFileName={t('Rent Adjustment Program (RAP) petition history for address {{address}}', { address: address })}
        showOwner
      />
    )
  }

  const OwnerDetails = () => {
    /* Set hooks to use Redux global state */
    const owners = useSelector(selectRecord(`${parcel_id}-owners`))

    /* Wait for data to become available */
    if (isEmpty(owners)) { return null }

    /* Dynamic text */
    const ownerList = owners?.owners
    const firstOwnerName = select.firstOwnerName(owners)
    const firstOwnerBusinessType = select.firstOwnerBusinessType(owners)
    const firstOwnerAddress = ownerList[0]?.mailing_address ?? ''
    const isSubdivided = select.isSubdivided(owners)
    const isMultiOwner = select.isMultiOwner(owners)

    return (
      <>
        <OwnerSectionHeader ownerName={firstOwnerName} />
        <Typography variant='body1'>
          {renderOwnerLink(firstOwnerName, firstOwnerBusinessType, 'body1')}
          &nbsp;{t('is a registered owner located at')}
          &nbsp;{titleCase(firstOwnerAddress)}
          {'.'}
        </Typography>
        <br />
        <Typography variant='body1'>
          <>
            {isSubdivided
              ? t(
                `Records suggest that this property resulted from a subdivision, meaning 
                that a parcel was split up into multiple parcels. There may be other parcels at the same location. `
              )
              : ''}
            {isSubdivided ? (
              <>
                <br />
                <br />
              </>
            ) : (
              ''
            )}
            {isMultiOwner
              ? t('There appear to be additional owners for this property. ')
              : t('There may be additional owners for this property. ')}
            <InterpolateTranslation
              components={[({children}) => <a
                href={geography === 'oak'
                  ? 'https://rechart1.acgov.org/'
                  : geography === 'sf'
                    ? 'https://recorder.sfgov.org/'
                    : 'http://'
                }
                target='_blank'
                rel='noopener noreferrer'
                style={{ cursor: 'pointer', textDecoration: 'always' }}>
                {children}
              </a>
              ]}>
              {t('To verify, please check out the <1>{{office_name}}</1>, where you can search this address to see the full list of owners.', {
                office_name: {
                  [value.oakland]: t("Alameda County Clerk-Recorder's Office"),
                  [value.sanFrancisco]: t("SF Recorder's Office")
                }[geography]
              })}
            </InterpolateTranslation>
          </>
        </Typography>
      </>
    )
  }

  const PropertySection = () => {
    /* Set hooks to use Redux global state */
    const details = useSelector(selectRecord(`${parcel_id}-details`))
    const isPortfolioDataAvailable = useSelector(isDataAvailable(`${parcel_id}-portfolio-${activeNetwork}`))

    /* Wait for data to become available */
    if (isNull(details)) { return null }
    if (!isPortfolioDataAvailable) { return null }

    /* Dynamic text */
    const address = select.mainAddress(details)

    return (
      <PropertyList
        slice={`${parcel_id}-portfolio-${activeNetwork}`}
        title={t('Property Portfolio')}
        subTitle={t('The following buildings may be part of the same ownership network as {{address}}.',
          {address})}
        noDataMessage={t('No portfolio properties found for this parcel.')}
        aboutData={{
          source: assessorSourceText,
          href: '/methodology',
          notes: t(`Property portfolios are created by identifying properties with the
            same mailing address(es). We associate properties with business ownership
            networks through either the name listed as the property owner (if it is a
            business) and through the property mailing addresses that are also business
            office addresses. Some business networks have multiple addresses. In counting
            the number of evictions for a property, Ellis Act evictions are counted as
            separate evictions for each unit.`)
        }}
        paginationPageSize={5}
        hideUnpopulatedColumns={true}
        csvFileName={t('Properties in the same ownership network as {{property}}', {
          property: address,
        })}
      />
    )
  }

  const ForeclosureSection = () => {
    /* Set hooks to use Redux global state */
    const details = useSelector(selectRecord(`${parcel_id}-details`))
    const isForeclosureDataAvailable = useSelector(isDataAvailable(`${parcel_id}-foreclosures`))

    /* Wait for data to become available */
    if (isNull(details) || !isLoggedIn) { return null }
    if (!isForeclosureDataAvailable) { return null }

    /* Dynamic text */
    const address = select.mainAddress(details)

    return (
      <ForeclosureList
        slice={`${parcel_id}-foreclosures`}
        title={t('Foreclosures')}
        subTitle={
          <>
            {t(
              'The following are the foreclosures we know about for {{property}}. A foreclosure can happen when the borrower defaults on their loan for the property, allowing the lender to force the sale of the property. Download the data as CSV to view all of the foreclosure information that we have.',
              {
                property: address,
              }
            )}
          </>
        }
        noDataMessage={t('No foreclosures found for this address.')}
        aboutData={{
          source: assessorSourceText,
          href: '/methodology',
          notes: t(`Pre-foreclosure records are matched to residential parcel
            records and property ownership records based on APN identifiers.
            They are identified as such based on a third party provider's
            identifying rules like late mortgage or tax payment.`)
        }}
        paginationPageSize={5}
        hideUnpopulatedColumns={true}
        csvFileName={t('foreclosures at {{property}}', {
          property: address,
        })}
      />
    )
  }

  return (
    <div>
      <Row>
        <Col xs={12}>
          <DataPage
            fetchArray={[
              {
                slice: `${parcel_id}-details`,
                url: `/api/parcel/${encodeURIComponent(parcel_id)}/details`,
              },
              {
                slice: `${parcel_id}-owners`,
                url: `/api/parcel/${encodeURIComponent(parcel_id)}/owners`,
              },
              {
                slice: `${parcel_id}-evictions`,
                url: `/api/parcel/${encodeURIComponent(parcel_id)}/evictions`,
              },
              {
                slice: `${parcel_id}-network-details`,
                url: `/api/parcel/${encodeURIComponent(parcel_id)}/networkDetails`,
              },
              {
                slice: `${parcel_id}-portfolio-${activeNetwork}`,
                url: `/api/parcel/${encodeURIComponent(parcel_id)}/portfolio/${activeNetwork}`,
              },
              {
                slice: `${parcel_id}-building-permits`,
                url: `/api/parcel/${encodeURIComponent(parcel_id)}/buildingPermits`,
              },
              {
                slice: `${parcel_id}-building-complaints`,
                url: `/api/parcel/${encodeURIComponent(parcel_id)}/buildingComplaints`,
              },
              {
                slice: `${parcel_id}-rap-petitions`,
                url: `/api/parcel/${encodeURIComponent(parcel_id)}/rapPetitions`,
              },
              ...(isLoggedIn
                ? [
                  {
                    slice: `${parcel_id}-foreclosures`,
                    url: `/api/parcel/${encodeURIComponent(parcel_id)}/foreclosures`,
                  },
                ]
                : []),
              { slice: 'about-evictions', url: '/api/about/eviction' },
              { slice: 'about-assessor', url: '/api/about/assessor' },
              { slice: 'about-building-permits', url: '/api/about/buildingPermit' },
              { slice: 'about-building-complaints', url: '/api/about/buildingComplaint' },
              { slice: 'about-rap-petitions', url: '/api/about/rapPetition' },
            ]}
            isDebugEnabled={false}>
            <ParcelMap />
            <Row justifyContent='center'>
              <Col xs={10} sm={8} style={columnStyle}>
                <ParcelIcon style={{ ...iconStyle, marginTop: 30 }} />
                <Typography variant='h4'>Parcel</Typography>
                <PageTitle
                  slice={`${parcel_id}-details`}
                  appendTextSelector={d => select.mainAddress(d)}
                  style={{ marginTop: '.15rem' }}
                />
                <PropertyDetails />
                <ParcelAccordionSection />
                <EvictionSection />
                <OwnerDetails />
                <OwnershipNetwork search={parcel_id} activeNetwork={activeNetwork} onNetworkChange={setActiveNetwork}/>
                <PropertySection />
                <ForeclosureSection />
                <BuildingPermitSection />
                <BuildingComplaintSection />
                <RAPPetitionSection />
              </Col>
            </Row>
          </DataPage>
        </Col>
      </Row>
    </div>
  )
}

export default memo(Parcel)
