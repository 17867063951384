import { forwardRef } from 'react'
import { createTheme } from '@mui/material/styles'
import { Link } from 'react-router-dom'

/*
  Theme: provides default styling for Material UI components
    Global styles that affect all elements of a given type are here
*/

/**
 * Globally configures the MUI Link component to use React Router's Link
 *
 * @param {import('react-router-dom').LinkProps & { href: string }} props
 * @param {Ref<HTMLAnchorElement>} ref
 */
const LinkBehavior = (props, ref) => {
  const { href, ...other } = props
  return <Link ref={ref} to={href} {...other} />
}

/** ******************** BASE THEME ********************* */
let base = createTheme({
  typography: {
    fontWeightRegular: 400,
    fontWeightBold: 700,
    h1: {
      fontFamily: 'Oswald, sans-serif',
      fontSize: '2rem',
      margin: '1rem 0',
      fontWeight: 500,
      userSelect: 'text',
      textAlign: 'center',
    },
    h2: {
      fontFamily: 'Oswald, sans-serif',
      fontSize: '1.4rem',
      marginBottom: 10,
      marginTop: 30,
      fontWeight: 500,
      userSelect: 'text',
      textAlign: 'center',
    },
    h3: {
      display: 'flex',
      justifyContent: 'center',
      fontFamily: 'Oswald, sans-serif',
      fontSize: '1.4rem',
      fontWeight: 500,
      userSelect: 'text',
      '&:hover': { textDecoration: 'none !important' },
      textAlign: 'center',
      textDecoration: 'none',
    },
    h4: {
      display: 'flex',
      justifyContent: 'center',
      fontFamily: 'Oswald, sans-serif',
      fontSize: '1.15rem',
      userSelect: 'text',
      textDecoration: 'none',
      '&:hover': { textDecoration: 'none' },
    },
    body1: {
      fontFamily: 'Source Sans Pro, sans-serif',
      fontSize: '1rem',
      fontWeight: 400,
      userSelect: 'text',
      marginLeft: 'auto', // margin auto added for mobile responsiveness, to keep section text on-screen and visible, see Kent for details - we can probably find a better solution
      marginRight: 'auto',
      '& li': { maxWidth: '70ch' },
      '& p': { maxWidth: '70ch' },
    },
    textInvertedColors1: {
      fontFamily: 'Source Sans Pro, sans-serif',
      fontSize: '1rem',
      fontWeight: 400,
      userSelect: 'text',
      '& li': { maxWidth: '70ch' },
      '& p': { maxWidth: '70ch' },
    },
    textInvertedColors2: {
      fontFamily: 'Oswald, sans-serif',
      fontSize: '1.4rem',
      marginBottom: 10,
      fontWeight: 500,
      userSelect: 'text',
      '& li': { maxWidth: '70ch' },
      '& p': { maxWidth: '70ch' },
    },
    caption: {
      fontFamily: 'Source Sans Pro, sans-serif',
      fontSize: '1.2rem',
      marginBottom: 10,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        /**
         * The type for some callbacks here needs to be manually specified
         * See [this MUI issue.](https://github.com/mui/material-ui/issues/27415)
         * @callback ThemeUse
         * @param {{ theme: import('@mui/material').Theme }} p
         * 
         * @type {ThemeUse}
         */
        body: ({ theme }) => ({
          backgroundColor: theme.palette.primary.main,
          fontFamily: 'Source Sans Pro, sans-serif',
          fontWeight: 400,
        }),
        'body, #root': {
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          minHeight: '100vh',
        },
        '*:before, *:after': {
          boxSizing: 'inherit',
        },
        img: {
          maxWidth: '100%' /* so images are never wider than the page */,
        },
        '.markdown': {
          display: 'flex',
          flexDirection: 'column',
          placeItems: 'center',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.primary.main,
          '& a': {
            color: theme.palette.primary.main,
            textDecorationColor: theme.palette.background.underlineOverBackground,
          },
        }),
        // @ts-ignore (styleoverrides not augmentable)
        textInvertedColors1: ({ theme }) => ({
          color: theme.palette.primary.contrastText,
          '&::selection': {
            color: theme.palette.primary.main,
            background: theme.palette.primary.contrastText,
          },
          '& *::selection': {
            color: theme.palette.primary.main,
            background: theme.palette.primary.contrastText,
          },
          '& MuiLink-root': {
            color: theme.palette.primary.contrastText,
            textDecoration: 'underline',
            textDecorationColor: theme.palette.primary.contrastTextDecorationColor,
          },
        }),
        /** @type {ThemeUse} */
        textInvertedColors2: ({ theme }) => ({
          color: theme.palette.primary.contrastText,
          '&::selection': {
            color: theme.palette.primary.main,
            background: theme.palette.primary.contrastText,
          },
          '& *::selection': {
            color: theme.palette.primary.main,
            background: theme.palette.primary.contrastText,
          },
          '& a': {
            color: theme.palette.primary.contrastText,
            textDecorationColor: '#4c405f',
          },
        }),
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        colorPrimary: ({ theme }) => ({
          color: theme.palette.primary.contrastText,
        }),
        colorSecondary: ({ theme }) => ({
          color: theme.palette.primary.main,
        }),
      },
    },
    MuiInput: {
      styleOverrides: {
        input: ({ theme }) => ({
          userSelect: 'text',
        }),
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        displayedRows: ({ theme }) => ({
          color: theme.palette.primary.main,
          background: theme.palette.background.default,
        }),
      },
    },
    MuiLink: {
      defaultProps: /** @type {import('@mui/material').LinkProps} */ ({
        component: forwardRef(LinkBehavior),
      }),
    },
  },
})

/** *********************** COLORS ********************** */
const EGGPLANT = '#231935'
const EGGPLANT_LIGHT = '#4c405f'
const EGGPLANT_DARK = '#000010'
const CORAL = '#FB7F50'
const CORAL_LIGHT = '#ffb07e'
const CORAL_DARK = '#c35025'
const BLACK = '#000000'
const WHITE = '#FFFFFF'
const OFFWHITE = '#f5f4f2'

/** ******************** LIGHT THEME ******************** */
export const light = createTheme(base, {
  palette: {
    mode: 'light',
    primary: {
      main: EGGPLANT,
      light: EGGPLANT_LIGHT,
      dark: EGGPLANT_DARK,
      contrastText: WHITE,
      contrastTextDecorationColor: EGGPLANT_LIGHT,
    },
    secondary: {
      main: CORAL,
      light: CORAL_LIGHT,
      dark: CORAL_DARK,
      contrastText: BLACK,
    },
    background: {
      default: OFFWHITE,
      paper: WHITE,
      menuHover: '#ececef',
      underlineOverBackground: '#a6a6a6',
    },
    graph: {
      Business: '#e7298a',
      Business_Owner: '#66a61e',
      Address_Unit: '#e6ab02',
      A_Parcel: '#1b9e77',
      A_Parcel_Owner: '#d95f02',
      links: '#666',
    },
    chip: {
      color: EGGPLANT,
      backgroundColor: '#ececef',
      avatarColor: EGGPLANT,
      avatarBackgroundColor: '#a6a6a6',
    },
  },
  typography: {
    h1: {
      '& a': {
        color: EGGPLANT,
        textDecorationColor: EGGPLANT,
      },
    },
    h2: {
      '& a': {
        color: EGGPLANT,
        textDecorationColor: EGGPLANT,
      },
    },
    body1: {
      '& a': {
        color: EGGPLANT,
        textDecorationColor: EGGPLANT,
      },
    },
  },
})

/** ******************** DARK THEME ********************* */
export const dark = createTheme(base, {
  palette: {
    mode: 'dark',
    /* TODO: copy all values from light theme here and change
       colors to suit a dark theme */
  },
})


/** @typedef {typeof base} Theme */