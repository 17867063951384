import React, { createElement, forwardRef, useState, useEffect } from 'react'

/* Functions to improve readability with data validation */
export const isNull = x => x === undefined || x === null
export const isNotString = x => isNull(x) || typeof x !== 'string'
export const isNotArray = x => isNull(x) || !Array.isArray(x)
export const isNotObject = x => isNull(x) || typeof x !== 'object'
export const isEmpty = x => {
  if (isNull(x)) return true
  const isArray = Array.isArray(x)
  if (isArray && x.length <= 0) return true
  const isObject = typeof x === 'object'
  if (isObject && Object.keys(x).length <= 0) return true
  return false
}

/* Helper functions to support children and props in sub-components */
/* React children objects are opaque (inconsistent) data structures and require caution */
export const getChildren = (children, typeName) => {
  if (isNull(children)) return null
  return React.Children.map(children, child => (child.type.displayName === typeName ? child : null))
}

/* Get the props of the first child object */
export const getChildProps = children => {
  if (isNull(children)) return null
  if (Array.isArray(children) && React.Children.count(children) >= 1) {
    return children[0]?.props
  } else {
    return children.props
  }
}

/* Render children, optionally injecting props and ref */
export const renderChildren = (children, props, ref) => {
  if (isNull(children)) return null

  /* Component, i.e. <Data.Pending component={}> */
  const component = getChildProps(children)?.component
  if (component) return createElement(component, { ...props, ref })

  /* Elements */
  return children
}

/* Selector to return the Redux state for a given resource name */
/* Contains an optional select parameter to change the selected root from the default */
export const selectJson = (name, select) => store => {
  const slice = store?._fetch?.[name]
  if (!isEmpty(slice) && slice.fulfilled)
    return typeof select === 'function' ? select?.(slice.json) : slice.json
  else return {}
}

export const selectRecords = (slice, select) => store => {
  return typeof select === 'function'
    ? select?.(store?._fetch?.[slice]?.json?.records)
    : store?._fetch?.[slice]?.json?.records ?? {}
}

export const selectRecord = (slice, select) => store => {
  return typeof select === 'function'
    ? select?.(store?._fetch?.[slice]?.json?.records?.[0])
    : store?._fetch?.[slice]?.json?.records?.[0] ?? {}
}

export const isDataAvailable = name => store => {
  const slice = store?._fetch?.[name]
  if (!isEmpty(slice) && slice.fulfilled) return slice.json?.data_available
  else return null
}

export const selectGeography = name => store => {
  const slice = store?._fetch?.[name]
  if (!isEmpty(slice) && slice.fulfilled) return slice.json?.geography
  else return null
}

/* A general-purpose selector where the second argument is a function that selects from data in the first argument */
export const selected = (data, select) => {
  return typeof select === 'function' ? select?.(data) : data
}

/* A selector specifically for map marker data - DEPRECATED */
export const selectMarkerData = (json, select) => {
  if (typeof select?.marker === 'function') {
    return select.marker(json)
  }
  return json
}

/* A selector specifically for map coords data - DEPRECATED */
export const selectCoordsData = (json, select) => {
  if (typeof select?.coords === 'function') {
    return select.coords(json)
  }
  return json
}

/* Helper function to get a URL parameter */
export const urlParam = name => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  return urlParams.get(name) ?? ''
}

/* Helper component to render children while optionally passing props and a ref */
export const Children = forwardRef((p, r) => renderChildren(p.childrenToRender, p, r))

/* Custom hook for timeout logic */
export const useTimeout = (secondsToWait, isResolved) => {
  const [secondsElapsed, setSecondsElapsed] = useState(0)
  const [isTimeout, setIsTimeout] = useState(false)

  useEffect(() => {
    if (isResolved === true) {
      setIsTimeout(false)
      setSecondsElapsed(0) /* Reset */
    } else if (secondsElapsed >= secondsToWait) setIsTimeout(true)
    else if (secondsElapsed < secondsToWait) {
      const timer = setTimeout(() => setSecondsElapsed(secondsElapsed + 1), 1000)
      return () => clearTimeout(timer)
    }
  }, [secondsToWait, secondsElapsed, isResolved])

  return isTimeout
}
