import { useMemo, memo } from 'react'
import { useSelector } from 'react-redux'
import useStyles from '../../theme/MapStyles'
import { useTheme } from '@mui/material/styles'
import { AttributionControl, MapContainer, ZoomControl, TileLayer, Marker, Tooltip } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-cluster'
import { latLngBounds } from 'leaflet'
import { config } from '../../config/config'
import { getMapIcon } from './MapUtils'
import { selectMarkerData, isNull, isEmpty, selectRecords } from '../Data/Data'
export { selectRecords, isNull, isEmpty, selectMarkerData, selectCoordsData } from '../Data/DataUtils'

const select = config.api.response.infoBox.selectors

/** 
 * @typedef {object} Marker
 * @prop {number} lat
 * @prop {number} long
 * @prop {Marker} location
 * @prop {[extra: string]} any
 */

/** DataMap: an optionally data-enabled version of the Leaflet map
 * @param {object} props
 * @param {Marker[]} [props.markers] an object specifying column configuration 
 * @param {any} [props.fetch] an array specifying column configuration 
 * @param {boolean} [props.isDebugEnabled]
 * @param {boolean} [props.isTooltipPermanent]
 * @param {(marker: any) => ReactNode} props.renderMarkerHover
 * @param {ReactNode[]} props.overlays
 * @param {import("leaflet").LeafletMouseEventHandlerFn} [props.onSelect]
 * Note: either the markers or the fetch prop must be provided
 * TODO: support mini-map mode, example mini-map control: https://react-leaflet.js.org/docs/example-react-control/
 * TODO: data-enable this component, just the markers part so the map can load on its own 
 * TODO: fly-to 
*/
const DataMap = props => {

  /* Set up Material-UI theme */
  const theme = useTheme()
  const classes = useStyles(theme)
  
  const firstFetchItem = props.fetch?.[0] ?? { slice: null, url: null, select: null }
  const { slice, url } = firstFetchItem

  /* Set hooks to use Redux global state */
  const json = useSelector(selectRecords(slice))

  const overlays = props.overlays ?? []
  const onSelect = props.onSelect
  const renderMarkerHover = props.renderMarkerHover
  const isTooltipPermanent = props.isTooltipPermanent ?? false
  const originalMarkers = /** @type {Marker[]} */ (props.markers ?? [])
  const isDebugEnabled = props.isDebugEnabled ?? false

  /* Establish a set of variables to pass down to the StyledTable */
  let passDownMarkers = useMemo(() => [], [])

  /* Previously, this map component had two options for how it could be run: */
  /* Option 1: this component accepts marker props and passes them to map components */
  /* Option 2: this component accepts a fetch prop and passes fetch to Data (data is fetched and processed) */
  /* At time of writing (7/7/21) the map only accepts marker props from the parent component, which is either Owner or Parcel */
  if (!isEmpty(originalMarkers)) {
    if (isDebugEnabled)
      console.info('%c DataMap: populating the map with marker props', 'color: green')

    /* Eviction API data has info the map needs under a location property */
    passDownMarkers = originalMarkers.map(marker => ({ ...marker, ...marker.location }))
  } else {
    /* NOTE that this code may need some upkeep if it is ever used */
    /* It was basically ABANDONED after the decision to go with DataPage */

    /* Eviction API data has info the map needs under a location property */
    passDownMarkers = originalMarkers.map(marker => ({ ...marker, ...marker.location }))

    const isPreFetch = isEmpty(json)
    /* TODO: find a way to render the map before fetched data comes in, or reserve space at least */
    if (isPreFetch) {
      if (isDebugEnabled)
        console.info(`DataMap: fetching slice '${slice}' from '${url}'`, 'color: green')
    } else {
      if (isDebugEnabled)
        console.info('%c DataMap: populating the map with fetched data', 'color: green')

      const markerDataJson = selectMarkerData(json)
      passDownMarkers = markerDataJson
    }
  }

  if (isEmpty(passDownMarkers)) { return null }

  /*
    TODO: refactor things specific to 'parcel' and special logic pertaining
    to a specific map out of this general, reusable component
  */
  const mainParcel = passDownMarkers.find(m => m.type === 'Parcel')
  /* Get bounding box from markers set as a whole */

  const bounds = (() => {
    if(!passDownMarkers.length) return null
    const b = latLngBounds(null)
    passDownMarkers.forEach(marker => {
      if (marker.lat && marker.long) {
        b.extend([marker.lat, marker.long])
      }
    })

    /* Adjust that bounding box through symmetric reflection
     * to have the main parcel at its center */
    if (mainParcel) {
      const {long, lat} = mainParcel
      const ne = b.getNorthEast()
      const sw = b.getSouthWest()
      /** @type {[number, number]} */
      const newSw = [ne.lat + (lat - ne.lat) * 2,
        ne.lng + (long - ne.lng) * 2
      ]
      /** @type {[number, number]} */
      const newNw = [sw.lat + (lat - sw.lat) * 2,
        sw.lng + (long - sw.lng) * 2
      ]
      b.extend(latLngBounds(newSw, newNw))
    }

    if (b.isValid()) {
      return b.pad(0.50)
    }
    else {
      return null
    }
  })()

  /* Wait for data to become available */
  if (isNull(props)) { return null }
  if (isNull(bounds) || typeof bounds.getCenter !== 'function') { return null }

  /** @type {import('react-leaflet').MapContainerProps} */
  let mapProps = {
    center: bounds.getCenter(),
    maxZoom: 18,
    minZoom: 8,
    touch: false,
    touchZoom: false,
    scrollWheelZoom: false,
    tap: true,
    dragging: true,
    preferCanvas: true,
    zoomControl: false, /* Disable the default zoom control */
    zoomAnimation: false,
    attributionControl: false, /* Disable the default attribution control */
    bounds: bounds
  }

  /* Adjust the map props for small viewing areas */
  if (matchMedia('(max-width: 768px)')) {
    mapProps.onContextMenu = e => e.originalEvent.preventDefault()
  }

  //Note that the media state need to be added to the store to force rerender
  return (
    <div style={{ contain: 'content' }} /* prevent map content from rendering outside the map */>
      <div
        style={{ position: 'relative' }}
        /* This gives absolutely positioned child elements (map overlays) a reference point */>
        <MapContainer className={classes.container} {...mapProps}>
          <TileLayer url={'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png'} />
          {/* Tile Layers: https://observablehq.com/@geographic-design/leaflet-providers */}
          {/* FREE: url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png" */}
          {/* Mapbox POC: url={`https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}@2x?access_token=${config.map.MAPBOX_TOKEN}`} */}
          <AttributionControl prefix={config.map.attribution} />
          <ZoomControl position='topright' />
          { mainParcel &&
            <Marker
              keyboard={false}
              position={[select.latitude(mainParcel), select.longitude(mainParcel)]}
              icon={getMapIcon(mainParcel)}
              zIndexOffset={1000}
              eventHandlers={{click: () => onSelect?.(mainParcel)}}>
              {
                <Tooltip
                  permanent={isTooltipPermanent}
                  direction="bottom"
                  offset={[0, 15]}
                  opacity={0.8}>
                  {renderMarkerHover?.(mainParcel)}
                </Tooltip>
              }
            </Marker>
          }
          <MarkerClusterGroup
            removeOutsideVisibleBounds
            chunkedLoading={true}
            maxClusterRadius={20}>
            {Array.isArray(passDownMarkers) &&
                passDownMarkers.filter(m => m.type !== 'Parcel')
                  .map((record, index) => 
                    <Marker
                      key={`${select.latitude(record)}-${select.longitude(record)}-${index}`}
                      keyboard={false}
                      position={[select.latitude(record), select.longitude(record)]}
                      icon={getMapIcon(record)}
                      eventHandlers={{ click: () => onSelect?.(record) }}>
                      {
                        typeof renderMarkerHover === 'function' &&
                          <Tooltip
                            permanent={isTooltipPermanent}
                            direction="bottom"
                            offset={[0, 15]}
                            opacity={0.8}>
                            {renderMarkerHover?.(record)}
                          </Tooltip>
                      }
                    </Marker>
                  )}
          </MarkerClusterGroup>
          {Array.isArray(overlays) &&
              overlays.map((overlay, i) => <div key={`overlay-${i}`}>{overlay}</div>)}
        </MapContainer>
      </div>
    </div>
  )
}

export default memo(DataMap)
