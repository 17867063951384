import { TextField, InputAdornment, Typography, IconButton } from '@mui/material'
import { useSelector } from 'react-redux'
/* Mui icons for search bar */
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'

/* ------------- SEARCH BAR TEXT INPUT HELPERS ------------------- */

/** icons in the far right of search bar: they render as an "x" and a magnifying glass 
 * @param {object} props
 * @param {MouseEventHandler} props.handleResultClick
 * @param {EventHandler<HTMLElement>} props.handleSearchSubmit
*/
export const CustomSearchIcons = ({handleResultClick, handleSearchSubmit}) => {
  
  /* Set hooks to use Redux global state */
  const value = useSelector(state => state.search.searchTerm )
  
  return <>

    {/* conditionally render the "X" that closes the dropdown */}
    {value.length > 0 && 
      <>
        <IconButton
          aria-label="cancel search"
          disableRipple
          onClick={handleResultClick}
          tabIndex={-1}
          size="large">
          <CloseIcon style={{ opacity: '0.5' }} />
        </IconButton>

        <Typography  
          tabIndex={-1}
          style={{ opacity: '0.5', fontSize: '1.5em', fontWeight: '300' }}>|
        </Typography>
      </>
    }

    <IconButton
      tabIndex={0}
      className='focus-dark'
      aria-label="search"
      disableRipple
      onClick={handleSearchSubmit}
      size="large">
      <SearchIcon />
    </IconButton>

  </>
}

/** helper function for search bar icons 
 * @param {object} props
 * @param {MouseEventHandler} props.handleResultClick
 * @param {EventHandler<HTMLDivElement>} props.handleSearchSubmit
*/
const CustomSearchAdornment = ({ handleResultClick, handleSearchSubmit }) => (
  <InputAdornment position="end" tabIndex={-1}>
    <CustomSearchIcons 
      handleResultClick={handleResultClick} 
      handleSearchSubmit={handleSearchSubmit}/>
  </InputAdornment>
)

/** primary site search bar 
 * @param {object} props
 * @param {any} props.value
 * @param {ChangeEventHandler<HTMLTextAreaElement>} props.handleChange
 * @param {MouseEventHandler} props.handleResultClick
 * @param {string} props.placeholderText
 * @param {EventHandler<HTMLDivElement>} props.handleSearchSubmit
 * @param {boolean} props.isLoading
*/
export const CustomSearchInput = ({ value, handleChange, placeholderText, handleResultClick, handleSearchSubmit, isLoading }) => {

  return (
    <TextField
      variant="outlined"
      aria-label={placeholderText}
      placeholder={placeholderText}
      id="search-item" // keep this. important for styles. find out why
      fullWidth
      autoFocus
      autoComplete="off"
      value={value}
      onChange={handleChange}
      onKeyDown={e => {
        if(e.key === 'Enter'){
          e.preventDefault() 
          handleSearchSubmit(e)
        }
      }
      }      
      InputProps={{
        endAdornment: 
          <CustomSearchAdornment 
            handleResultClick={handleResultClick} 
            handleSearchSubmit={handleSearchSubmit}
          />,
        className: 'input'
      }}
    />)
}
