import  { memo } from 'react'
import { useSelector } from 'react-redux'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import { renderOwnerLink, generateGraphIdUrl } from '../utils/tableUtils'
import { isEmpty, selectRecord, selectRecords } from './DataPage/DataPage'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import i18n from '../i18n'
import { config } from '../config/config'
import { ReactComponent as NetworkIcon } from '../images/network-page-icon.svg'
import { commaSeparatedText } from '../utils/stringUtils'

/**
 * OwnershipNetwork: render a table with a list of ownership networks
 * @param {object} props
 * @param {string} props.search
 * @param {string} props.activeNetwork corresponds to the "ID" property on one of the networks in the network details route
 * @param {(newNetworkId: string) => void} props.onNetworkChange
*/
const OwnershipNetwork = ({ search, activeNetwork, onNetworkChange }) => {

  /* Set up API data selectors */
  const select = config.api.response.ownershipNetwork.selectors

  /* Set up the react-i18next translation engine */
  const { t, ready: translationReady } = useTranslation(
    'OwnershipNetwork', /* i18next-parser needs string here */
    { useSuspense: false, i18n }
  )

  /* Set hooks to use Redux global state */
  const details = useSelector(selectRecord(`${search}-details`))
  const networks = useSelector(selectRecords(`${search}-network-details`)) || []
  const networkDetails =
    activeNetwork === ''
      ? networks[0]
      : networks?.find(net => select.networkId(net) === activeNetwork)

  /* Translation calls with t() must occur after translationReady is true */
  /* Conditional returns must take place after all hooks have been called */
  if (!translationReady) { return null }

  /* Dynamic text */
  const networkName = select.networkName(details)
  const networkId = select.networkId(networkDetails)

  if (isEmpty(networkDetails)) {
    return (
      <>
        <Typography variant='h2'>{t('Ownership Network')}</Typography>
        <Typography variant='body1'>
          {t('This property does not appear to be part of a larger ownership network.')}
        </Typography>
      </>
    )
  }

  /* If there are businesses, render the total number of businesses with a linked example */
  /* E.g. "10 businesses, such as Veritas Investments Inc," */
  const business_text_section =
    select.numBusinesses(networkDetails)
      ? (
        <>
          {t('{{numBusinesses}} {{businessSummary}}', {
            numBusinesses: select.numBusinesses(networkDetails),
            businessSummary:
              select.numBusinesses(networkDetails) > 1
                ? 'businesses, such as '
                : 'business, namely ',
          })}
          {renderOwnerLink(select.topBusinessName(networkDetails), select.topBusinessType(networkDetails))}
          {select.topAddress(networkDetails) || select.topOwner(networkDetails) ? t(',') : ''}
        </>
      ) :
      ('')

  /* If there are addresses, render an example */
  /* E.g. " 10 business office or property owner addresses, such 123 Main St CA, and" */
  const addr_text_section =
    select.numAddresses(networkDetails) === 0
      ? ('')
      : (
        <>
          {t(' {{numAddresses}} {{addressType}} {{addressSummary}}{{sampleAddress}}', {
            numAddresses: select.numAddresses(networkDetails),
            addressType: select.isTopAddressBusiness(networkDetails)
              ? select.isTopAddressPropertyOwner(networkDetails)
                ? t('business office and property owner')
                : t('business office')
              : t('property owner'),
            addressSummary: select.numAddresses(networkDetails) > 1 ? 'addresses, such as ' : 'address, namely ',
            sampleAddress: select.topAddressReadable(networkDetails),
          })}
          {select.topOwner(networkDetails) ? t(', and') : ''}
        </>
      )

  /* If there are owners, render an example */
  /* E.g. " 5 business owners, such as David McCloskey" */
  const owner_text_section =
    select.numOwners(networkDetails) === 0
      ? ('')
      : (
        <>
          {t(' {{numOwners}} {{ownerSummary}}{{sampleOwner}}', {
            numOwners: select.numOwners(networkDetails),
            ownerSummary:
              select.numOwners(networkDetails) > 1
                ? ' business owners, such as '
                : ' business owner, namely ',
            sampleOwner: select.topOwnerName(networkDetails),
          })}
        </>
      )

  return (
    <>
      <NetworkIcon style={{ height: '45px', width: '45px', margin: 'auto', display: 'flex', marginTop: '2.5rem' }} />
      <Typography variant='h1' style={{ marginTop: '.25rem' }}>{t('Ownership Networks')}</Typography>
      {networks.length > 1 &&
        (<div>
          <Typography variant="body1">
            {t('This entity is associated with multiple ownership networks. Use the menu below to select an ownership network to view.')}
          </Typography>
          <Box display="flex" justifyContent="center" margin="1rem">
            <FormControl>
              <InputLabel id="network-select-label">{t('Network with')}</InputLabel>
              <Select
                labelId="network-select-label"
                value={select.networkId(networkDetails)}
                label={t('Network with')}
                onChange={(event) => {
                  onNetworkChange(event.target.value)
                }}
              >
                {networks.map(network => {
                  return <MenuItem value={select.networkId(network)} key={select.networkId(network)}>
                    {commaSeparatedText([
                      select.topBusinessName(network),
                      select.topOwnerName(network),
                    ]) || select.topAddressReadable(network)}
                  </MenuItem>
                })}
              </Select>
            </FormControl>
          </Box>
        </div>
        )}
      <Typography variant='body1'>
        {networks.length > 1 ?
          t('The selected ownership network contains ') : 
          t('{{networkName}} is part of one ownership network with ', {networkName})
        }
        {business_text_section}
        {addr_text_section}
        {owner_text_section}
        {t('.')}
      </Typography>
      <br />
      <Box style={{ display: 'flex', justifyContent: 'center' }}>
        <Card
          style={{
            borderRadius: '5px',
            margin: 15,
            border: 0,
          }}>
          <CardActionArea href={generateGraphIdUrl(networkId)}>
            <CardContent> 
              <Box style={{ display: 'flex', justifyContent: 'center' }}>
                <Typography variant='body1' style={{ margin: '0 10px', fontSize: '0.9rem' }}>
                  {t('Click here to learn more about who is behind this network')}
                </Typography>
              </Box>
            </CardContent>
          </CardActionArea>
        </Card>
      </Box>
    </>
  )
}

export default memo(OwnershipNetwork)
