import { memo } from 'react'
import { useSelector } from 'react-redux'
import { Typography, Button } from '@mui/material'
import { isEmpty, selectJson } from './Data/DataUtils'
import DataTable from './DataTable/DataTable'
import { renderParcelLink, renderOwnerLink } from '../utils/tableUtils'
import { useTranslation } from 'react-i18next'
import i18n from '../i18n'
import { config } from '../config/config'

/** PreForeclosureList: render a table with a list of pre-foreclosures
 * @param {import('./DataTable/DataTable').ListProps} props
*/
const PreForeclosureList = ({
  title,
  subTitle,
  noDataMessage,
  slice,
  aboutData, 
  csvFileName
}) => {
  /* Set up API data fields and selectors */
  const select = config.api.response.foreclosureList.selectors
  const field = config.api.response.foreclosureList.fields

  /* Set hooks to use Redux global state */
  const { records: foreclosures, error } = useSelector(selectJson(slice))

  /* Set up the react-i18next translation engine */
  const { t, ready: translationReady } = useTranslation(
    'ForeclosureList' /* i18next-parser needs string here */,
    { useSuspense: false, i18n }
  )

  if (isEmpty(foreclosures)) {
    return (
      <>
        <Typography variant='h2'>
          {typeof title === 'string' && title.length > 0 && title}
        </Typography>
        <Typography variant='body1'>
          {error
            ? t(
              'Oh no! We ran into an error while retrieving preforeclosure data. Could you try logging in again?'
            )
            : noDataMessage}
        </Typography>
        <Button onClick={() => {}}>Load Data</Button>
      </>
    )
  }

  /* Translation calls with t() must occur after translationReady is true */
  /* Conditional returns must take place after all hooks have been called */
  if (!translationReady) {
    return null
  }

  return (
    <>
      <Typography variant='h2'>{typeof title === 'string' && title.length > 0 && title}</Typography>
      <Typography variant='body1'>{subTitle !== null && subTitle}</Typography>
      <DataTable
        sortModel={[{ field: field.foreclosureDate, sort: 'desc' }]}
        sortingOrder={['desc', 'asc', null]}
        name='foreclosures'
        noDataMessage={noDataMessage}
        aboutData={aboutData}
        rows={foreclosures}
        csvFileName={csvFileName}
        columns={[
          {
            field: field.foreclosureDate,
            headerName: t('Date'),
            flex: 1,
            renderCell: p => {
              return select.foreclosureDate(p.row)
            },
            searchSelector: row => select.foreclosureDate(row),
            sortSelector: row => parseInt(select.foreclosureDateRaw(row))
          },
          {
            field: field.foreclosureAddress,
            headerName: t('Address'),
            flex: 3,
            renderCell: p => {
              return renderParcelLink(
                select.parcelId(p.row),
                select.foreclosureAddress(p.row)
              )
            }
          },
          {
            field: field.numUnits,
            headerName: t('Units'),
            flex: 0.50,
            renderCell: p => {
              return select.numUnits(p.row)
            }
          },
          {
            field: field.useCode,
            headerName: t('Use Code'),
            flex: 0.75,
            renderCell: p => {
              return select.useCode(p.row)
            }
          },
          {
            field: field.ownerName,
            headerName: t('Owner'),
            flex: 2.5,
            renderCell: p => {
              return renderOwnerLink(
                select.ownerName(p.row),
                select.ownerLinkedBeType(p.row)
              )
            }
          },
          {
            field: field.loanAmount,
            headerName: t('Loan Amount'),
            flex: 1.5,
            renderCell: p => {
              return select.loanAmount(p.row)
            }
          },
          {
            field: field.lenderName,
            headerName: t('Lender'),
            flex: 2.5,
            renderCell: p => {
              return select.lenderName(p.row)
            }
          },
          {
            field: field.auctionDate,
            headerName: t('Auction Date'),
            flex: 1,
            renderCell: p => {
              return select.auctionDate(p.row)
            }
          }
        ]}
      />
    </>
  )
}

export default memo(PreForeclosureList)
