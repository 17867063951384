import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Typography, TextField, Button } from '@mui/material'
import useStyles from '../theme/LoginStyles'
import { logout, attemptLogin } from '../state/slices/loginSlice'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import i18n from '../i18n'
import { useAppDispatch } from 'src/state/hooks'

export const Login = () => {
  const dispatch = useAppDispatch()
  const { isLoggedIn, fetchStatus } = useSelector(state => state.login)

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const classes = useStyles()

  const isLogout = useLocation().state?.isLogout
  useEffect(() => {
    if (isLogout) {
      dispatch(logout())
    }
  }, [dispatch, isLogout])

  const { t, ready: translationReady } = useTranslation(
    'LoginPage' /* i18next-parser needs string here */,
    { useSuspense: false, i18n }
  )
  if (!translationReady) {
    return null
  }

  function displayLoginStatus() {
    switch (fetchStatus) {
      case 'pending':
        return t('Logging you in...')
      case 'success':
        return isLoggedIn
          ? t('Logged in! Restricted datasets now available.')
          : t('Your username or password is incorrect.')
      case 'rejected':
        return t('Oh no! Something went wrong. Please let us know via the contact form below.')
      case 'loggedout':
        return t('Successfully logged out!')
      case 'idle':
        return isLoggedIn ? t('You are currently logged in.') : ' '
      default:
        return ' '
    }
  }

  return (
    <div className={classes.page}>
      <div className={classes.container}>
        <Typography variant='h1'>Login</Typography>
        <div className={classes.inputs}>
          <Typography variant='body1'>{displayLoginStatus()}</Typography>
          {!isLoggedIn ? (
            <>
              <TextField
                aria-label='username'
                placeholder='username'
                value={username}
                onChange={e => setUsername(e.target.value)}
              />
              <TextField
                aria-label='password'
                placeholder='password'
                type='password'
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
              <Button
                className={classes.button}
                onClick={() => dispatch(attemptLogin({ username, password }))}
                variant='contained'
              >
                Submit
              </Button>
            </>
          ) : (
            <Button
              className={classes.button}
              onClick={() => dispatch(logout())}
              variant='contained'
            >
              Logout
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}
