import { useEffect, useState } from 'react'
import i18n from '../i18n'
import Markdown from '../components/Markdown'
import Grid from '@mui/material/Grid'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const AboutPage = () => {
  const [markdown, setMarkdown] = useState('')

  const { t, ready: translationReady } = useTranslation(
    'AboutPage' /* i18next-parser needs string here */,
    { useSuspense: false, i18n }
  )

  useEffect(() => {
    /* `.catch()` clause necessary even through
     * `language` falls back to `en` when alternate is not
     * present. A language could be detected but still be missing
     * about.md content specifically, which would cause the fetch to fail
     * */
    fetch(`/locales/${i18n.language}/about.md`)
      .then(res => res.text())
      .then(text => setMarkdown(text))
      .catch(() => {
        fetch('/locales/en/about.md')
          .then(res => res.text())
          .then(text => setMarkdown(text))
      })
  })

  if (!translationReady) return null

  const partners = [
    {
      url: 'https://greatcommunities.org',
      photo: 'gcc.png',
      title: 'the Great Communities Collaborative',
    },
    { url: 'https://resist.org/', photo: 'resist.png', title: 'the Resist Foundation' },
    { url: 'https://sff.org/', photo: 'sff.png', title: 'the San Francisco Foundation' },
    {
      url: 'https://tenantstogether.org',
      photo: 'tenants-together.png',
      title: 'Tenants Together',
    },
    {
      url: 'https://thebritishacademy.ac.uk',
      photo: 'british-academy.png',
      title: 'the British Academy',
    },
    {
      url: 'https://sfartscommission.org',
      photo: 'sfac.png',
      title: 'the San Francisco Arts Commission',
    },
    {
      url: 'https://utexas.edu',
      photo: 'ut-austin.png',
      title: 'the University of Texas at Austin',
    },
  ]

  return (
    <Grid container flexDirection='column' justifyContent='center' padding='1rem'>
      <Markdown>{markdown}</Markdown>
      <Grid container flexDirection='column' alignItems='center'>
        <Typography variant='h2'>{t('Our partners and supporters')}</Typography>
        <Grid container justifyContent='center' style={{ gap: '1rem', maxWidth: '70ch' }}>
          {partners.map(partner => (
            <a
              key={partner.url}
              href={partner.url}
              target='_blank'
              rel='noreferrer'
              style={{ maxWidth: '30%' }}>
              <img src={'/partner-logos/' + partner.photo} alt={`Logo for ${partner.title}`} />
            </a>
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AboutPage
