import i18n from 'i18next'

function getLanguage() {
  return i18n.language || (typeof window !== 'undefined' && window.localStorage.i18nextLng) || 'en'
}

/**
 * @param {string | Date | number | undefined} input
 * @returns {Date | undefined}
 */
function parseDate(input) {
  if (input instanceof Date || !input) {
    return undefined
  }
  if (typeof input === 'number') {
    input = input.toString()
  }
  if (input.length !== 8) {
    console.error('Input to parseDate not of length 8; exiting early.')
    return undefined
  }
  const y = parseInt(input.substring(0, 4)),
    m = parseInt(input.substring(4, 6)),
    d = parseInt(input.substring(6, 8))
  const date = new Date(y, m, d)
  return date
}

/** @param {string} [str] */
function reformatDate(str, short = true) {
  if (typeof str !== 'string' || str.length < 8) return ''
  if (str === '00000000') return ''
  const date = parseDate(str)
  if (isNaN(date.getTime())) return ''
  if (short) return date.toLocaleDateString()
  return date.toDateString()
}

/**
 * Convert a user input date string into packed string format
 *
 * @param {string} [str]
 */
function tryParseDate(str) {
  let isValidDate = false
  let parsedValue = ''

  const numForwardSlashes = (str.match(/\//g) || []).length
  if (numForwardSlashes === 1) {
    /* Assume US convention m[m]/d[d] */
    const split = str.split('/')
    const mm = String(split[0]).padStart(2, '0')
    const dd = String(split[1]).padStart(2, '0')
    parsedValue = `${mm}${dd}`
    if (parsedValue.length === 4) isValidDate = true
  } else if (numForwardSlashes === 2) {
    /* Assume US convention m[m]/d[d]/yyyy */
    const split = str.split('/')
    const mm = String(split[0]).padStart(2, '0')
    const dd = String(split[1]).padStart(2, '0')
    const yyyy = String(split[2])
    parsedValue = `${yyyy}${mm}${dd}`
    if (parsedValue.length === 8) isValidDate = true
  }

  return { isValidDate, parsedValue }
}

// Helper function for titleCase, to handle '/' and '-' edge cases
// NOTE: Yes, this seems like overkill, but using regex visibly slows down the text loading
// TODO: see if we can format the strings serverside or on the DB instead ?
// function mixSeparatedWordsCase(str, seperator) {
//   return str.split(seperator).map(word => word[0].toUpperCase() + word.slice(1).toLowerCase()).join(seperator);
// }

// PURPOSE: this function returns a string in "title case" format, to display to users
// It converts text to lower case, identifies any character that immediately follows a space, "-", "/", or ".",
// and capitalizes it
// It ignores/capitalizes the following reserved words:
const reservedWords = ['LLC', 'LP', 'CA', 'PO']
const exceptions = ['and', 'or', 'of']
const isWordRE = /\w+/g

// Create our number formatter.
var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
})

/** @param {number} amount */
const formatMoney = amount => {
  return formatter.format(amount)
}

/** 
 * @param {string} str
 * @return {string}
 */
const titleCase = (str, separators = [' ', '/', '(', '&', '-', '.', ',']) => {
  if (!['number', 'string'].includes(typeof str)) {
    return ''
  }

  const cleanStr = str + ''

  // no more separators, end recursion
  if (separators.length === 0) return cleanStr

  /* Logic to title case a word */
  const titleCaseWord = w => {
    if (!w || typeof w !== 'string' || w.length <= 1) return w
    const idiom = idomaticWord(w) /* Check for special cases */
    if (typeof idiom === 'string') return idiom
    const firstLetter = w[0]
    const restOfTheWord = w.slice(1)
    return firstLetter.toUpperCase() + restOfTheWord.toLowerCase()
  }

  /** Handle special cases of O'Farrell and McWhatever */
  const idomaticWord = w => {
    if (!w || typeof w !== 'string') return null
    const word = w.toLowerCase()
    if (word.startsWith('mc') && word.length > 3) return 'Mc' + w[2].toUpperCase() + word.slice(3)
    if (exceptions.includes(word)) return word
    switch (word) {
      case 'ofarrell':
        return "O'Farrell"
      case "o'farrell":
        return "O'Farrell"
      default:
        return null
    }
  }

  // split and join with the current separator
  const formattedSeparator = separators[0]
  return cleanStr
    .split(separators[0])
    .map(word => {
      // not a string, quit
      if (typeof word !== 'string') {
        return ''
      }

      // not a word, quit
      if (!word.match(isWordRE)) {
        return word
      }

      // cap single letter and reserved words, title case the rest
      const cappedWords =
        reservedWords.includes(word.toUpperCase()) || word.length === 1
          ? word.toUpperCase()
          : titleCaseWord(word)

      // go down a level with the next separator
      return titleCase(cappedWords, separators.slice(1))
    })
    .join(formattedSeparator)
}

/** @param {string} str */
function toUnderscore(str) {
  if (!str) {
    return null
  }
  return str.replace(/\s+/g, '_')
}

/**
 * Helper function to create a comma separated string from an array, with each item bolded.
 * @param {string[]} list
 */
export const commaSeparatedBolded = list => {
  if (!list) return
  const formatter = new Intl.ListFormat(getLanguage(), { style: 'long', type: 'conjunction' })
  const parts = formatter.formatToParts(
    list.filter(term => typeof term === 'string' && term.length > 0)
  )

  return parts.map((part, i) => {
    return (
      <span key={i}>{part.type === 'literal' ? part.value : <strong>{part.value}</strong>}</span>
    )
  })
}

/**
 * Helper function to create a comma separated string from an array
 * @param {string[]} list
 * @returns {string}
 */
export const commaSeparatedText = list => {
  const formatter = new Intl.ListFormat(getLanguage(), { style: 'long', type: 'conjunction' })
  return formatter.format(list)
}

export { parseDate, reformatDate, formatMoney, titleCase, toUnderscore, tryParseDate }
