import { isEmpty } from '../components/Data/DataUtils'
import { titleCase, reformatDate, formatMoney } from '../utils/stringUtils'

export const config = /** @type {const} */ ({
  map: {
    attribution:
      '<a href="https://antievictionmap.com/about-the-aemp">&copy; Anti Eviction Mapping Project</a>&nbsp;|&nbsp;<a href="https://www.openstreetmap.org/copyright">&copy; OpenStreetMap</a>&nbsp;|&nbsp;<a href="https://carto.com/attributions">&copy; CARTO</a>&nbsp;|&nbsp;<a href="https://leafletjs.com/">&copy; Leaflet</a>',
    regions: {
      SanFrancisco: {
        defaultCoordinates: { lat: 37.7749, long: -122.4194 },
      },
      Oakland: {
        defaultCoordinates: { lat: 37.8044, long: -122.2712 },
      },
      LosAngeles: {
        defaultCoordinates: { lat: 34.0522, long: -118.2437 },
      },
    },
  },
  categories: {
    BUSINESS_ENTITY: 2,
    NEIGHBORHOOD_SUMMARY: 1,
    PARCEL: 0,
  },
  api: {
    response: {
      /*
        API response fields, values, and selectors

        Selectors are special functions that drill into an object to find
        a single entity. Fields are references to field names within the
        data. Values are other hard-coded data present in the API result.
        Together,they distill hard-coded values out of UI code and help to
        decouple knowledge of the data schema from UI logic. For example,
        the name of the selector can remain the same in UI code while the
        hard-coded values can change here per the changes in the 
        structure of API response data. The name of the selector can match
        the idioms of the frontend so that is is more readily understandable
        in a UI context. By imitating the patterns here, best practices for
        handling the data will be easier to adhere to. When used in an editor
        such as VS Code, these fields and selectors have helpful syntax
        highlighting and hovering shows a list of fields and selectors
        available. The selectors here also shield the UI code from unsightly
        but necessary optional chaining and formatting helpers. Comments about
        data that are added here are easier for the data team to see.

        Note: Redux has selectors as well and they do the same general thing
        but the set of selectors here operate on JSON responses from the API
        whereas Redux selectors operate on Redux global state.
      */
      propertyList: {
        fields: {
          parcelAddress: 'addr',
          parcelId: 'parcel_id',
          ownerName: 'owner_name',
          ownerAddress: 'owner_mailing_addr',
          numUnits: 'units',
          numEvictions: 'num_evictions',
          businessType: '' /* TODO: allow clickable owner links */,
        },
        selectors: {
          parcelAddress: portfolio => {
            const address = (portfolio?.addr || portfolio?.location?.addr) ?? ''
            return titleCase(address)
          },
          parcelId: portfolio => (portfolio?.parcel_id || portfolio?.location?.parcel_id) ?? '',
          numEvictions: portfolio => portfolio?.num_evictions ?? 0,
          numUnits: portfolio => portfolio?.units ?? 0,
          ownerName: portfolio => portfolio?.owners?.[0]?.owner_name ?? '',
          ownerAddress: portfolio => {
            const address = (portfolio?.owner_mailing_addr || portfolio?.mailing_address) ?? ''
            return titleCase(address)
          },
          ownerAddressParcelId: portfolio => null /* TODO: allow clickable parcel links */,
          businessType: portfolio => portfolio?.owners?.[0]?.linked_be?.be_type ?? '',
        },
      },
      ownershipNetwork: {
        selectors: {
          numAddresses: networkDetails => networkDetails?.total_addrs ?? 0,
          numBusinesses: networkDetails => networkDetails?.total_bes ?? 0,
          numOwners: networkDetails => networkDetails?.total_owners ?? 0,
          networkName: details => {
            const name = (details?.addr_details?.main_addr || details?.name) ?? ''
            return titleCase(name)
          },
          networkId: details => {
            if (details?.parcel_details) return details?.parcel_details?.network_id
            return details?.network_id ?? ''
          },
          topBusinessName: networkDetails => networkDetails?.top_be?.name_norm ?? '',
          topBusinessType: networkDetails => networkDetails?.top_be?.be_type ?? '',
          topAddress: networkDetails => networkDetails?.top_addr ?? '',
          topAddressReadable: networkDetails => {
            const address = networkDetails?.top_addr?.addr_readable ?? ''
            return titleCase(address)
          },
          topOwner: networkDetails => networkDetails?.top_owner ?? '',
          topOwnerName: networkDetails => {
            const name = networkDetails?.top_owner?.name_norm ?? ''
            return titleCase(name)
          },
          isTopAddressBusiness: networkDetails => networkDetails?.top_addr?.is_business,
          isTopAddressPropertyOwner: networkDetails => networkDetails?.top_addr?.is_property_owner,
        },
      },
      buildingPermitList: {
        fields: {
          fileDate: 'file_date',
          location: 'location',
          owner: 'owner',
          type: 'type',
          description: 'description',
          status: 'status',
        },
        selectors: {
          fileDate: permit => reformatDate(permit?.file_date, true) ?? '',
          fileDateRaw: permit => permit?.file_date ?? '',
          ownerName: permit => permit?.owner?.name ?? '',
          businessType: permit => permit?.owner?.linked_be?.be_type ?? '',
          parcelId: permit => permit?.location?.parcel_id ?? '',
          addr: permit => permit?.location?.addr ?? '',
          description: permit => permit?.description ?? ''
        },
      },
      buildingComplaintList: {
        fields: {
          fileDate: 'file_date',
          location: 'location',
          owner: 'owner',
          type: 'type',
          description: 'description',
          status: 'status',
        },
        selectors: {
          fileDate: complaint => reformatDate(complaint?.file_date, true) ?? '',
          fileDateRaw: complaint => complaint?.file_date ?? '',
          ownerName: complaint => complaint?.owner?.name ?? '',
          businessType: complaint => complaint?.owner?.linked_be?.be_type ?? '',
          parcelId: complaint => complaint?.location?.parcel_id ?? '',
          addr: complaint => complaint?.location?.addr ?? '',
          description: complaint => complaint?.description ?? ''
        },
      },
      rapPetitionList: {
        fields: {
          fileDate: 'file_date',
          location: 'location',
          owner: 'owner',
          filer: 'record_kind',
          status: 'status',
          type: 'record_type'
        },
        selectors: {
          dateFiled: petition => reformatDate(petition?.file_date, true) ?? '',
          dateFiledRaw: petition => petition?.file_date ?? '',
          types: petition => petition?.record_type ?? '',
          ownerName: petition => petition?.owner?.name ?? '',
          businessType: petition => petition?.owner?.linked_be?.be_type ?? '',
          parcelId: petition => petition?.location?.parcel_id ?? '',
          addr: petition => petition?.location?.addr ?? '',
        },
      },
      networkList: {
        selectors: {
          networkDescription: network => network?.network_description ?? {},
          networkId: network => network?.network_description?.network_id ?? '',
          networkExplanations: network => network?.explanations ?? [],
          explanationType: explanation => explanation?.exp_type ?? '',
          numAddresses: explanation => explanation?.exp_metrics?.num_addrs ?? 0,
          numBusinesses: explanation => explanation?.exp_metrics?.num_businesses ?? 0,
          numParcels: explanation => explanation?.exp_metrics?.num_parcels ?? 0,
          numRelatedToExcluded: explanation =>
            explanation?.exp_metrics?.num_related_to_excluded ?? 0,
          topBusinessName: description => titleCase(description?.top_be) ?? '',
          topAddress: description => titleCase(description?.top_addr) ?? '',
          topOwner: description => titleCase(description?.top_owner) ?? '',
          numParcelsInDescription: description => description?.num_parcels ?? 0,
          numUnitsInDescription: description => description?.num_units ?? 0,
        },
        values: {
          businessesListedAtAddresses: 'businesses_listed_at_addrs',
          relatedToExcluded: 'related_to_excluded',
          businessesOwningParcels: 'businesses_owning_parcels',
          parcelsOwnedBySameNonBusinessOwner: 'parcels_owned_by_same_non_business_owner',
          similarAddresses: 'similar_addresses',

        }
      },
      evictionList: {
        fields: {
          evictionDate: 'evict_date',
          evictionAddress: 'display_addr',
          evictorName: 'evictor_name',
          evictionType: 'type',
          evictionNotes: 'notes',
        },
        selectors: {
          evictionDate: eviction => reformatDate(eviction?.evict_date) ?? '',
          evictionDateRaw: eviction => eviction?.evict_date ?? '',
          primaryEvictorName: eviction => eviction?.primary_evictor?.name ?? '',
          primaryEvictorBusinessType: eviction =>
            eviction?.primary_evictor?.linked_be?.be_type ?? '',
          secondaryEvictors: eviction =>
            isEmpty(eviction?.extra_evictors)
              ? []
              : eviction?.extra_evictors.map(e => ({
                evictorName: e.evictor_name ?? '',
                evictorType: e.evictor_type ?? '',
                evictorSource: e.source ?? '',
              })),
          evictionAddress: eviction => titleCase(eviction?.display_addr || eviction?.location?.display_addr || eviction?.evict_addr_orig/*SF*/ || '') ?? '',
          evictionType: eviction => eviction?.type ?? '',
          numOtherParcels: eviction =>
            (eviction?.num_other_parc || 0 + eviction?.location?.num_other_parc || 0) ?? 0,
          parcelId: eviction => (eviction?.parcel_id || eviction?.location?.parcel_id) ?? '',
        },
      },
      foreclosureList: {
        fields: {
          foreclosureDate: 'date',
          foreclosureType: 'type',
          foreclosureAddress: 'display_addr',
          defaultAmount: 'default_amount',
          loanAmount: 'loan_amount',
          loanNumber: 'loan_number',
          borrowerName: 'borrower_name',
          lenderName: 'lender_name',
          ownerName: 'owner_name',
          auctionDate: 'auction_date',
          parcelId: 'parcel_id',
          numUnits: 'units',
          useCode: 'use_code'
        },
        selectors: {
          foreclosureDate: foreclosure => reformatDate(foreclosure?.foreclosure?.date, true) ?? '',
          foreclosureDateRaw: foreclosure => foreclosure?.foreclosure?.date ?? '',
          foreclosureType: foreclosure => foreclosure?.foreclosure?.type ?? '',
          foreclosureAddress: foreclosure => foreclosure?.parcel?.display_addr ?? '',
          defaultAmount: foreclosure => formatMoney(foreclosure?.foreclosure?.default_amount ?? ''),
          loanAmount: foreclosure => formatMoney(foreclosure?.foreclosure?.loan_amount ?? ''),
          loanNumber: foreclosure => foreclosure?.foreclosure?.loan_number ?? '',
          borrowerName: foreclosure => titleCase(foreclosure?.borrower?.name ?? ''),
          lenderName: foreclosure => titleCase(foreclosure?.lender?.name ?? ''),
          ownerName: foreclosure => foreclosure?.parcel_owner?.name ?? '',
          ownerLinkedBeType: foreclosure => foreclosure?.parcel_owner?.linked_be?.be_type ?? '',
          auctionDate: foreclosure => reformatDate(foreclosure?.auction?.date),
          parcelId: foreclosure => foreclosure?.parcel?.id ?? '',
          numUnits: foreclosure => foreclosure?.parcel?.units ?? '',
          useCode: foreclosure => foreclosure?.parcel?.use_code ?? ''
        }
      },
      preforeclosurePage: {
        fields: {
        },
        selectors: {
          foreclosureDateRaw: foreclosure => foreclosure?.foreclosure?.date
        }
      },
      ownerPage: {
        values: {
          beTypeLP: 'LP'
        },
        selectors: {
          beType: details => details?.be_type,
          jurisdiction: details => details?.be_specific_details?.jurisdiction,
          ownerAddresses: details => details?.office_addresses,
          ownerStatus: details => details?.be_specific_details?.status,
          ownerName: details => titleCase(details?.name),
          ownerRecordCreated: details => reformatDate(details?.creation_date),
          firstOwnerAddress: details => details?.office_addresses?.[0],
          corporationNumber: details => details?.be_specific_details?.corporation_number,
          corporationType: details => details?.be_specific_details?.corporation_type,
          corporationStatus: details => details?.be_specific_details?.corporation_status,
          corporationTaxBase: details => details?.be_specific_details?.corporation_tax_base,
          llcManagementCode: details => details?.be_specific_details?.llc_management_code,
          llcType: details => details?.be_specific_details?.llc_type_of_business,
          propertyName: property => titleCase(property?.name),
          propertyAddress: property => titleCase(property?.addr),
          evictionAddress: eviction => titleCase(eviction?.location?.display_addr),
          propertyLatitude: property => property?.lat,
          evictionLatitude: eviction => eviction?.location.lat,
          sourceName: source => source?.source_name,
          sourceDate: source => reformatDate(source?.date_exported ?? ''),
        },
      },
      parcelPage: {
        selectors: {
          mainAddress: details => titleCase(details?.addr_details?.main_addr ?? ''),
          propertyAddress: details => details?.addr/*OAK*/ ?? details?.addr_details?.main_addr/*SF*/ ?? '',
          propertyType: details => details?.prop_class_code ?? '',
          yearBuilt: details => details?.yr_built ?? '',
          neighborhood: details => details?.neighborhood ?? '',
          neighborhood_w_city: details => details?.neighborhood_w_city ?? '',
          numUnits: details => details?.units ?? '',
          numStories: details => details?.stories ?? '',
          numRooms: details => details?.rooms ?? '',
          numBeds: details => details?.beds ?? '',
          numBaths: details => details?.baths ?? '',
          rentControlCode: details => details?.rent_controlled ?? '',
          addressVariations: details => details?.addr_details?.addr_variations ?? [],
          numExtraAddrVariations: details => details?.addr_details?.num_extra_addr_variations ?? 0,
          parcelId: details => details?.parcel_id_orig ?? details?.block_lot ?? '',
          parcelType: details => details?.type ?? '',
          assessedLandValue: details => details?.assess_land_val ?? '',
          assessedImprovementValue: details => details?.assess_imp_val ?? '',
          isExtraFieldsEmpty: details => details?.extra_fields === null,
          ownerType: details => details?.extra_fields?.owner_type ?? '',
          constructionType: details => details?.extra_fields?.construction_type ?? '',
          ownerOccupied: details => details?.extra_fields?.owner_occupied ?? '',
          taxDelinquentYear: details => details?.extra_fields?.tax_delinquent_year ?? '',
          ownerIsTrust: details => details?.extra_fields?.owner_is_trust ?? '',
          foundationType: details => details?.extra_fields?.foundation_type ?? '',
          numBathsPartial: details => details?.extra_fields?.baths_partial ?? '',
          ownerIsBusiness: details => details?.extra_fields?.owner_is_business ?? '',
          ownerList: owners => owners?.owners ?? [],
          firstOwnerName: owners => titleCase(owners?.owners?.[0]?.owner_name ?? ''),
          firstOwnerBusinessType: owners => owners?.owners?.[0]?.linked_be?.be_type ?? '',
          firstOwnerAddress: owners => owners?.owners?.[0]?.mailing_address ?? '',
          isSubdivided: owners => owners?.owners?.subdivided/*OAK*/ ?? owners?.subdivided/*SF*/,
          isMultiOwner: owners => owners?.owners?.multiple_owners/*OAK*/ ?? owners?.multiple_owners/*SF*/,
          evictionType: eviction => eviction?.type ?? '',
          sourceName: source => source?.source_name ?? '',
          sourceDate: source => reformatDate(source?.date_exported ?? ''),
        },
        values: {
          sanFrancisco: 'sf',
          oakland: 'oak',
          alameda: 'alameda',
          ellisActWithdrawal: 'Ellis Act Withdrawal'
        }
      },
      networkPage: {
        fields: {
          nodeName: 'name',
          readableType: 'type_readable',
          entity: 'entity',
          type: 'type',
        },
        values: {
          addressUnit: 'Address_Unit',
        },
        selectors: {
          numBusinesses: details => details?.total_bes ?? 0,
          numOwners: details => details?.total_owners ?? 0,
          numAddresses: details => details?.total_addrs ?? 0,
          topBusinessName: details => titleCase(details?.top_be?.name_norm) ?? '',
          topAddress: details => details?.top_addr ?? '',
          topAddressReadable: networkDetails => {
            const address = networkDetails?.top_addr?.addr_readable ?? ''
            return titleCase(address)
          },
          topOwner: details => details?.top_owner ?? '',
          topOwnerName: details => {
            const name = details?.top_owner?.name_norm ?? ''
            return titleCase(name)
          },
          sourceName: source => source?.source_name,
          sourceDate: source => reformatDate(source?.date_exported),
          nodeName: networkNode => networkNode?.name,
          nodeType: networkNode => networkNode?.type,
          nodeReadableType: networkNode => networkNode?.type_readable,
          nodeParcelId: networkNode => networkNode?.id,
          nodeParcelAddress: networkNode => networkNode?.name,
          nodeOwnerName: networkNode => networkNode?.name,
          nodeBusinessType: networkNode => networkNode?.type,
        },
      },
      evictionPage: {
        selectors: {
          evictionDateRaw: eviction => eviction?.evict_date ?? '',
          evictionDate: eviction => reformatDate(eviction?.evict_date ?? ''),
          evictionType: eviction => eviction?.type ?? '',
          evictionAddress: eviction => titleCase(eviction?.display_addr || eviction?.location?.display_addr),
          numUnits: eviction => eviction?.units ?? 0,
          sourceName: source => source?.source_name ?? '',
          sourceDate: source => reformatDate(source?.date_exported ?? '')
        },
        values: {
          oakland: 'oak',
          sanFrancisco: 'sf',
          ellisActWithdrawl: 'Ellis Act Withdrawal'
        }
      },
      methodologyPage: {
        values: {
          oakland: 'oak',
          sanFrancisco: 'sf'
        },
        selectors: {
          sourceName: source => source?.source_name ?? '',
          sourceDate: source => reformatDate(source?.date_exported ?? '')
        },
      },
      search: {
        fields: {
          results: 'results'
        },
        selectors: {
          detailsUrl: results => results?.details_url,
          label: results => titleCase(results?.label),
          categoryLabel: results => results?.category_label.toUpperCase(),
          category: results => results?.category,
        },
        values: {
          parcel: 'PARCEL',
          neighborhoodSummary: 'NEIGHBORHOOD_SUMMARY',
          businessEntity: 'BUSINESS_ENTITY'
        }
      },
      dataTable: {
        selectors: {
          aboutDataUrl: aboutData => aboutData?.href,
          aboutDataSource: aboutData => aboutData?.source,
          aboutDataNotes: aboutData => aboutData?.notes,
        },
      },
      neighborhoodListPage: {
        selectors: {
          name: neighborhood => neighborhood?.label,
          id: neighborhood => neighborhood?.canonical_id,
          detailsURL: neighborhood => neighborhood?.details_url
        },
        values: {
          oakland: 'oak',
          sanFrancisco: 'sf'
        }
      },
      infoBox: {
        fields: {
          evictionDate: 'evict_date',
          evictionAddress: 'addr',
          evictorName: 'evictor_name',
          evictionType: 'type',
          evictionNotes: 'notes',
          evictionId: 'evict_id',
          parcelId: 'parcel_id',
          evictionLocationAccuracy: 'evict_location_accuracy',
        },
        selectors: {
          latitude: marker => marker?.lat,
          longitude: marker => marker?.long,
          parcelId: marker => marker?.parcel_id,
          evictionDate: marker => reformatDate(marker?.evict_date),
          evictionAddress: marker => titleCase(marker?.display_addr),
          businessDetails: marker => marker?.be_specific_details,
          yearBuilt: marker => marker?.yr_built,
          numUnits: marker => marker?.units,
          numStories: marker => marker?.stories,
          numRooms: marker => marker?.rooms,
          numBeds: marker => marker?.beds,
          numBaths: marker => marker?.baths,
          numPartialBaths: marker => marker?.baths_partial,
          parcelType: marker => marker?.type,
          zipCode: marker => marker?.parc_zip,
          propertyClassCode: marker => marker?.prop_class_code,
          constructionType: marker => marker?.construction_type,
          foundationType: marker => marker?.foundation_type,
          ownerIsTrust: marker => marker?.owner_is_trust,
          ownerIsBusiness: marker => marker?.owner_is_business,
          ownerType: marker => marker?.owner_type,
          ownerOccupied: marker => marker?.owner_occupied,
          currentSalesPrice: marker => marker?.curr_sales_price,
          officeAddress: marker => marker?.office_addresses?.[0],
          dateCreated: marker => marker?.creation_date,
          dateFiled: marker => marker?.date_filed,
          taxDelinquentYear: marker => marker?.tax_delinquent_year,
          corporationNumber: corp_details => corp_details?.corporation_number,
          corporationType: corp_details => corp_details?.corporation_type,
          status: corp_details => corp_details?.status,
          corporationTaxBase: corp_details => corp_details?.corporation_tax_base,
          jurisdiction: corp_details => corp_details?.jurisdiction,
          llcTypeOfBusiness: corp_details => corp_details?.llc_type_of_business,
          llcManagementCode: corp_details => corp_details?.llc_management_code,
        },
        values: {
          likely: 'Likely',
          parcel: 'Parcel'
        }
      },
      homePage: {
        selectors: {
          parcelAddress: parcel => parcel?.address || '',
          parcelId: parcel => parcel?.parcel_id,
          ownerName: owner => owner?.name || '',
          ownerNameTitleCase: owner => titleCase(owner?.name || ''),
          ownerType: owner => owner?.type,
          networkId: network => network?.network_id,
        }
      }
    },
  },
})
