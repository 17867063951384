import  { useState, useMemo, useRef, memo } from 'react'
import { useSelector } from 'react-redux'
import { Typography } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActionArea from '@mui/material/CardActionArea'
import { Link } from 'react-router-dom'
import InfoBox from '../components/DataMap/Overlays/InfoBox'
import MapIcon from '@mui/icons-material/Map'
import { isEmpty } from '../components/DataTable/DataTable'
import DataMap from '../components/DataMap/DataMap'
import DataPage, { selectRecords, selectRecord, selectGeography } from '../components/DataPage/DataPage'
import { useTheme } from '@mui/material/styles'
import { Row, Col } from '../components/Grid'
import { titleCase, reformatDate } from '../utils/stringUtils'
import Overlay from '../components/DataMap/Overlays/Overlay'
import { ReactComponent as EvictionIcon } from '../components/DataMap/Icons/directly-evicted-icon.svg'
import PageTitle from '../components/PageTitle'
import EvictionList from '../components/EvictionList'
import Legend from '../components/DataMap/Overlays/Legend'
import { useTranslation } from 'react-i18next'
import i18n from '../i18n'
import { useParams } from 'react-router-dom'
import { config } from '../config/config'


const columnStyle = /** @type {const} */ ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})

/**
 * EvictionsPage: presents content for evictions in a geographic region
*/
const EvictionPage = () => {

  /* Set up Material-UI theme */
  const theme = useTheme()

  /* Set up API data fields and selectors */
  const select = config.api.response.evictionPage.selectors
  const value = config.api.response.evictionPage.values

  /* Local state */
  const [tableSearchRows, setTableSearchRows] = useState(null)
  const evictionList = useRef([])
  const totalEvictions = useRef(0)

  /* Get the search path parameter from the URL bar */
  const params = useParams()
  const { search } = params ?? {}
  const neighborhoodName = titleCase(search)

  /* Set hooks to use Redux global state */
  const { isLoggedIn } = useSelector(state => state.login)
  const evictionSourceData = useSelector(selectRecord('about-evictions'))
  const evictionsJson = useSelector(selectRecords(`${neighborhoodName}-evictions`))
  const geography = useSelector(selectGeography(`${neighborhoodName}-evictions`))

  /* If tableSearchRows exist use them instead of the full evictionList */
  const evictions = !isEmpty(tableSearchRows) ? tableSearchRows : evictionsJson
  totalEvictions.current = evictions.length ?? 0
  evictionList.current = evictions

  /* Calculate numEvictions and evictionDatesMinMax */
  const numEvictions = evictions.length ?? 0
  const evictionDatesMinMax = useMemo(() => {
    let oldestEviction = ''
    let newestEviction = ''

    for (let i = 0; i < evictions.length; i++) {
      const d = select.evictionDateRaw(evictions[i]) // use YYYYMMDD for sorting
      const validDate = d && d.length === 8
      if (validDate) {
        if (!oldestEviction || d < oldestEviction) oldestEviction = d
        if (!newestEviction || d > newestEviction) newestEviction = d
      }
    }

    return {
      oldest: reformatDate(oldestEviction),
      newest: reformatDate(newestEviction),
    }
  }, [evictions, select])

  /* Set up the react-i18next translation engine */
  const { t, ready: translationReady } = useTranslation(
    'EvictionPage' /* i18next-parser needs string here */,
    { useSuspense: false, i18n }
  )

  /* Translation calls with t() must occur after translationReady is true */
  /* Conditional returns must take place after all hooks have been called */
  if (!translationReady) {
    return null
  }

  /* Dynamic text */

  // Use source data from relevant geography
  const evictionSourceText = select.sourceName(evictionSourceData?.[geography]) + ' (as of ' + select.sourceDate(evictionSourceData?.[geography]) + ')'

  const EvictionMap = () => {
    /* Local state */
    const [selectedEviction, setSelectedEviction] = useState(null)

    /* Avoid hundreds of redundant translation calls */
    // eslint-disable-next-line
    const addressLabel = useMemo(() => t('Address'), [i18n.language])
    // eslint-disable-next-line
    const evictionTypeLabel = useMemo(() => t('Type'), [i18n.language])
    // eslint-disable-next-line
    const dateFiledLabel = useMemo(() => t('Date Filed'), [i18n.language])

    /* Wait for data to become available */
    if (isEmpty(evictions)) {
      return null
    }

    /* Dynamic text */
    function HoverContent(eviction) {
      const ellis_text =
        select.evictionType(eviction) === value.ellisActWithdrawl
          ? t(' (affecting {{num_units}} units)', {num_units: select.numUnits(eviction)})
          : ''
      return (
        <Typography variant='body1'>
          {addressLabel}
          {': '}
          {select.evictionAddress(eviction) ||
            <span style={{ opacity: '0.6' }}>Unknown</span>}
          <br />
          {evictionTypeLabel}
          {': '}
          {select.evictionType(eviction) ||
            <span style={{ opacity: '0.6' }}>Unknown</span>}
          {ellis_text}
          <br />
          {dateFiledLabel}
          {': '}
          {select.evictionDate(eviction) ||
            <span style={{ opacity: '0.6' }}>Unknown</span>}
        </Typography>
      )
    }

    return (
      <DataMap
        markers={evictions}
        overlays={[
          <Overlay position='bottomright'>
            <Legend
              title={t('Legend')}
              items={[{ icon: <EvictionIcon />, label: t('Eviction') }]}
            />
          </Overlay>,
          <Overlay position='topleft'>
            <InfoBox
              selectedMarker={selectedEviction}
              directions={t('Click an eviction on the map for details')}
            />
          </Overlay>,
        ]}
        onSelect={eviction => setSelectedEviction(eviction)}
        renderMarkerHover={HoverContent}
      />
    )
  }

  const PreForeclosureSection = () => (
    <div style={{ marginTop: '2.5rem' }}>
      <Typography variant='h1' style={{ marginTop: '.25rem' }}>
        {t('Pre-Foreclosures')}
      </Typography>
      <br />
      <Link to={`/preforeclosures/${neighborhoodName}`}>
        <Card
          style={{
            borderRadius: '5px',
            margin: 15,
            border: 0,
          }}>
          <CardContent
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '20px',
            }}>
            <Typography variant='body1' style={{ margin: '0 10px', fontSize: '0.9rem' }}>
              {t('Click here to see a list of pre-foreclosures for this neighborhood.')}
            </Typography>
          </CardContent>
        </Card>
      </Link>
    </div>
  )

  const AllNeighborhoodsSection = () => (
    <div style={{ marginTop: '2.5rem' }}>
      <Typography variant='h1' style={{ marginTop: '.25rem' }}>
        {geography === value.oakland
          ? t('Other Oakland Neighborhoods')
          : t('Other San Francisco Neighborhoods')}
      </Typography>
      <br />
      <Card
        style={{
          borderRadius: '5px',
          margin: 15,
          border: 0,
        }}>
        <CardActionArea component={Link} to={geography === value.oakland ? '/neighborhood-list/oak' : '/neighborhood-list/sf'} >
          <CardContent
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '20px',
            }}>
            <Typography variant='body1' style={{ margin: '0 10px', fontSize: '0.9rem' }}>
              {geography === value.oakland
                ? t('Click here to see a list of all neighborhoods in Oakland')
                : t('Click here to see a list of all neighborhoods in San Francisco')}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </div >
  )

  return (
    <Row>
      <Col xs={12}>
        <DataPage
          fetchArray={[
            {
              slice: `${neighborhoodName}-evictions`,
              url: `/api/neighborhood/${encodeURIComponent(neighborhoodName)}/evictions?size=99999`,
            },
            { slice: 'about-evictions', url: '/api/about/eviction' },
          ]}
          isDebugEnabled={false}>
          <EvictionMap />
          <Row justifyContent='center'>
            <Col xs={10} sm={8} style={columnStyle}>
              <PageTitle
                text={t('{{neighborhoodName}} Neighborhood Evictions', { neighborhoodName })}
              />
              <EvictionList
                slice={`${neighborhoodName}-evictions`}
                subTitle={t(
                  'Map and table show {{numEvictions}} evictions between {{oldest}} and {{newest}}{{searchTermApplied}}.',
                  {
                    numEvictions: numEvictions,
                    oldest: evictionDatesMinMax.oldest,
                    newest: evictionDatesMinMax.newest,
                    searchTermApplied:
                      totalEvictions.current < evictionsJson.length
                        ? ' with a search term applied'
                        : '',
                    context: numEvictions === 0 ? 'zero' : '',
                  }
                )}
                noDataMessage={t('No evictions recorded for this area.')}
                aboutData={{
                  source: evictionSourceText,
                  href: '/methodology',
                  notes:
                    t(`Eviction records data come from two general sources:
                    Alameda County Sheriff actions and City of Oakland eviction filing records;
                    they generally only include the address of the eviction.
                    Evictions were matched to parcel records and property ownership records.
                    As a result, some evictions were matched to multiple or no properties, if an exact
                    match could not be found. Some evictions also include names on the filing,
                    which may be either property owners or lawyers involved in the case. For these,
                    we have shown the name identified through property records, and the additional
                    names are those found on the eviction filing itself.`),
                }}
                onSearchFilter={filtered => {
                  setTableSearchRows(filtered)
                }}
                searchHint={() => (
                  <Row alignItems='center' style={{ opacity: 0.6 }}>
                    <MapIcon htmlColor={theme.palette.primary.main} />
                    <Typography style={{ fontSize: '12px' }}>
                      {t('Map results updated')}
                    </Typography>
                  </Row>
                )}
                paginationPageSize={25}
                csvFileName={t('Evictions for the {{search}} Neighborhood', {
                  search: neighborhoodName,
                })}
              />
              {isLoggedIn && <PreForeclosureSection />}
              <AllNeighborhoodsSection />
            </Col>
          </Row>
        </DataPage>
      </Col>
    </Row>
  )
}

export default memo(EvictionPage)
