import  { useState, useMemo, memo } from 'react'
import { useSelector } from 'react-redux'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import DataPage, { isNull, isEmpty, selectRecords, selectRecord } from '../components/DataPage/DataPage'
import DataMap from '../components/DataMap/DataMap'
import Overlay from '../components/DataMap/Overlays/Overlay'
import { titleCase } from '../utils/stringUtils'
import { commaSeparatedText } from '../utils/stringUtils'
import { ReactComponent as RelatedParcelIcon } from '../components/DataMap/Icons/related-parcel-icon.svg'
import { ReactComponent as EvictionIcon } from '../components/DataMap/Icons/directly-evicted-icon.svg'
import { Row, Col } from '../components/Grid'
import EvictionList from '../components/EvictionList'
import PropertyList from '../components/PropertyList'
import Legend from '../components/DataMap/Overlays/Legend'
import i18n from '../i18n'
import { beStatusIndefiniteArticle } from '../utils/dataUtils'
import OwnershipNetwork from '../components/OwnershipNetwork'
import { useParams } from 'react-router-dom'
import { config } from '../config/config'
import Accordion from '../components/Accordion'
import { OwnerSectionHeader } from '../components/SectionHeaders'
import InterpolateTranslation from '../components/InterpolateTranslation'

const columnStyle = /** @type {const} */ ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})

/**
 * OwnerPage: presents content for a property owner
*/
const Owner = () => {
  /* Set up API data fields and selectors */
  const select = config.api.response.ownerPage.selectors
  const values = config.api.response.ownerPage.values

  /* Get the search parameter from the URL bar */
  const params = useParams()
  const { search } = params ?? {}

  /* Set hooks to use Redux global state */
  const evictionSourceData = useSelector(selectRecord('about-evictions'))
  const assessorSourceData = useSelector(selectRecord('about-assessor'))

  /* String representing ID of currently selected network
   * If empty string, then portfolio and eviction
   * endpoints will fetch data for first connected network
   * */
  const [activeNetwork, setActiveNetwork] = useState('')

  /* Set up the react-i18next translation engine */
  const { t, ready: translationReady } = useTranslation(
    'OwnerPage' /* i18next-parser needs string here */,
    { useSuspense: false, i18n }
  )

  /* Translation calls with t() must occur after translationReady is true */
  /* Conditional returns must take place after all hooks have been called */
  if (!translationReady) {
    return null
  }

  /* Dynamic text */

  // Use all geographies, since owners can cover across multiple geographies
  const evictionSourceText = Object.values(evictionSourceData).map(sourceData =>
    select.sourceName(sourceData) + ' (as of ' + select.sourceDate(sourceData) + ')').join(', ')
  const assessorSourceText = Object.values(assessorSourceData).map(sourceData =>
    select.sourceName(sourceData) + ' (as of ' + select.sourceDate(sourceData) + ')').join(', ')

  const OwnerMap = () => {
    /* Set hooks to use Redux global state */
    const details = useSelector(selectRecord(`${search}-details`))
    const evictions = useSelector(selectRecords(`${search}-evictions-${activeNetwork}`))
    const portfolio = useSelector(selectRecords(`${search}-portfolio-${activeNetwork}`))

    /* Map data includes evictions by the same owner as well as properties 
       owned by the same owner. The type property is used by downstream
       code to set the icon. */
    const mapData = useMemo(
      () => {
        const evictionList = isEmpty(evictions)
          ? []
          : evictions.filter(x => !isNull(select.evictionLatitude(x)))
        const propertyList = isEmpty(portfolio)
          ? []
          : portfolio.filter(x => !isNull(select.propertyLatitude(x)))
        const ownerProperty = { ...details, type: 'Parcel' }
        let data = [...evictionList, ...propertyList]
        if (!isNull(ownerProperty.lat)) data = data.concat(ownerProperty)
        return data
      },
      // eslint-disable-next-line
      [evictions]
    )

    /* Wait for data to become available */
    if (isEmpty(mapData)) {
      return null
    }

    return (
      <DataMap
        markers={mapData}
        overlays={[
          <Overlay position='bottomright'>
            <Legend
              title={t('Legend')}
              items={[
                { icon: <EvictionIcon />, label: t('Eviction') },
                { icon: <RelatedParcelIcon />, label: t('Property') },
              ]}
            />
          </Overlay>
        ]}
        renderMarkerHover={marker => (
          <Typography variant='body1'>
            {
              (select.propertyAddress(marker) || select.evictionAddress(marker)) ||
              <span style={{ opacity: '0.6' }}>Unknown</span>
            }
          </Typography>
        )}
      />
    )
  }

  const OwnerAccordionSection = () => {
    const details = useSelector(selectRecord(`${search}-details`))
    
    return (
      <Accordion
        fields={[
          {
            title: t('Office Address'),
            expandable: false,
            detailText: null,
            value: select.firstOwnerAddress(details),
          },
          {
            title: t('Date Registered'),
            expandable: false,
            detailText: null,
            value: select.ownerRecordCreated(details)
          },
          {
            title: t('Corporation Number'),
            expandable: false,
            detailText: null,
            value: select.corporationNumber(details),
          },
          {
            title: t('Corporation Type'),
            expandable: false,
            detailText: null,
            value: select.corporationType(details),
          },
          {
            title: t('LLC Management Code'),
            expandable: false,
            detailText: null,
            value: select.llcManagementCode(details),
          },
          {
            title: t('LLC Type'),
            expandable: false,
            detailText: null,
            value: select.llcType(details),
          },
          {
            title: t('Status'),
            expandable: false,
            detailText: null,
            value: select.ownerStatus(details),
          },
          {
            title: t('Tax Base'),
            expandable: false,
            detailText: null,
            value: select.corporationTaxBase(details),
          }
        ]}/>
    )
  }

  const OwnerDetails = () => {
    /* Set hooks to use Redux global state */
    const details = useSelector(selectRecord(`${search}-details`))

    /* Wait for data to become available */
    if (isNull(details)) {
      return null
    }

    /* Dynamic text */
    const beType = select.beType(details)
    const addresses = select.ownerAddresses(details)
    const status = select.ownerStatus(details)
    const jurisdiction = select.jurisdiction(details)
    const name = select.ownerName(details)
    const date = select.ownerRecordCreated(details)

    return (
      <>
        <Typography variant='h2'>{t('About the Business')}</Typography>
        <Typography variant='body1'>
          <InterpolateTranslation components={[({children}) => <strong>{children}</strong>]}>
            {beType === values.beTypeLP
              ? t(
                '<1>{{name}}</1> is {{article}} {{status}} Limited Partnership, first registered {{date}} in {{jurisdiction}}, located at {{addresses}}.',
                {
                  name: name,
                  article: beStatusIndefiniteArticle(status),
                  status: status,
                  date: date,
                  jurisdiction: jurisdiction,
                  addresses: commaSeparatedText(addresses) || t('an address not in our records'),
                }
              )
              : t(
                '<1>{{name}}</1> is {{article}} {{status}} corporation first registered {{date}}, located at {{addresses}}.',
                {
                  name: name,
                  article: beStatusIndefiniteArticle(status),
                  status: status,
                  date: date,
                  addresses: commaSeparatedText(addresses) || t('an address not in our records'),
                }
              )}
          </InterpolateTranslation>
        </Typography>
      </>
    )
  }

  const PropertySection = () => {
    /* Set hooks to use Redux global state */
    const details = useSelector(selectRecord(`${search}-details`))

    /* Wait for data to become available */
    if (isNull(details)) {
      return null
    }

    /* Dynamic text */
    const name = select.propertyName(details)

    return (
      <PropertyList
        slice={`${search}-portfolio-${activeNetwork}`}
        title={t('Property Portfolio')}
        subTitle={
          <InterpolateTranslation components={[({children}) => <strong>{children}</strong>]}>
            {t('The following buildings may be part of the same ownership network as <1>{{name}}</1>.', {
              name: name
            })}
          </InterpolateTranslation>
        }
        noDataMessage={t('No portfolio properties found for this parcel.')}
        aboutData={{
          source: assessorSourceText,
          href: '/methodology',
          notes:
            t(`Property portfolios are created by identifying properties with the
            same mailing address(es). We associate properties with business ownership
            networks through either the name listed as the property owner (if it is a
            business) and through the property mailing addresses that are also business
            office addresses. Some business networks have multiple addresses. In counting
            the number of evictions for a property, Ellis Act evictions are counted as
            separate evictions for each unit.`),
        }}
        paginationPageSize={5}
        csvFileName={t('Properties in the portfolio of Owner {{owner}}', { owner: search })}
      />
    )
  }

  return (
    <div>
      <Row>
        <Col xs={12}>
          <DataPage
            fetchArray={[
              {
                slice: `${search}-details`,
                url: `/api/owner/${encodeURIComponent(
                  search
                )}/details`,
              },
              {
                slice: `${search}-evictions-${activeNetwork}`,
                url: `/api/owner/${encodeURIComponent(
                  search
                )}/evictions/${activeNetwork}`,
              },
              {
                slice: `${search}-network-details`,
                url: `/api/owner/${encodeURIComponent(
                  search
                )}/networkDetails`,
              },
              {
                slice: `${search}-portfolio-${activeNetwork}`,
                url: `/api/owner/${encodeURIComponent(
                  search
                )}/portfolio/${activeNetwork}`,
              },
              { slice: 'about-evictions', url: '/api/about/eviction' },
              { slice: 'about-assessor', url: '/api/about/assessor' },
            ]}
            isDebugEnabled={false}>
            <OwnerMap />

            <Row justifyContent='center'>
              <Col xs={10} sm={8} style={columnStyle}>
                <OwnerSectionHeader ownerName={titleCase(search)} includeIcon={false} />
                <OwnerDetails />
                <OwnerAccordionSection />
                <OwnershipNetwork search={search} activeNetwork={activeNetwork} onNetworkChange={setActiveNetwork} />
                <EvictionList
                  slice={`${search}-evictions-${activeNetwork}`}
                  title={t('Eviction History for this Ownership Network')}
                  subTitle={
                    t(`The following evictions were conducted by businesses in the ownership network. 
                      Namely, a business in the network was the property owner at the time of the eviction. 
                      This may include properties that are no longer owned by this network. 
                      Duplicate rows here represent evictions at different units at this property.`)
                  }
                  noDataMessage={t('No evictions recorded for this business.')}
                  aboutData={{
                    source: evictionSourceText,
                    href: '/methodology',
                    notes:
                      t(`Eviction records data come from two general sources: 
                      Alameda County Sheriff actions and City of Oakland eviction filing records;
                      they generally only include the address of the eviction.
                      Evictions were matched to parcel records and property ownership records.
                      As a result, some evictions were matched to multiple or no properties, if an exact
                      match could not be found. Some evictions also include names on the filing,
                      which may be either property owners or lawyers involved in the case. For these,
                      we have shown the name identified through property records, and the additional
                      names are those found on the eviction filing itself.`)
                  }}
                  paginationPageSize={5}
                  csvFileName={t('Known evictions for Owner {{owner}}', { owner: search })}
                />
                <PropertySection />
              </Col>
            </Row>
          </DataPage>
        </Col>
      </Row>
    </div>
  )
}

export default memo(Owner)
