import  { memo, useMemo } from 'react'
import { useSelector } from 'react-redux'
import DataPage, { isNull, selectRecords } from '../components/DataPage/DataPage'
import { Row, Col } from '../components/Grid'
import PageTitle from '../components/PageTitle'
import PreForeclosureList from '../components/PreForeclosureList'
import { NoPage } from '../components/NotAvailable'
import { useTranslation } from 'react-i18next'
import i18n from '../i18n'
import { useParams } from 'react-router-dom'
import { config } from '../config/config'
import { reformatDate } from '../utils/stringUtils'


const columnStyle = /** @type {const} */ ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})

/**
 * PreforeclosurePage: presents content for a property parcel
*/
const Preforeclosures = () => {

  /* Get the id path parameter from the URL bar */
  const params = useParams()
  const { neighborhood: neighborhoodName } = params ?? {}

  /* Set hooks to use Redux global state */
  const foreclosures = useSelector(selectRecords(`${neighborhoodName}-foreclosures`))

  /* Set up API data fields and selectors */
  const select = config.api.response.preforeclosurePage.selectors

  /* Set hooks to use Redux global state */
  const { isLoggedIn } = useSelector(state => state.login)

  /* Set up the react-i18next translation engine */
  const { t, ready: translationReady } = useTranslation(
    'PreforeclosurePage' /* i18next-parser needs string here */,
    { useSuspense: false, i18n }
  )

  /* Calculate numForeclosures and foreclosureDatesMinMax */
  const numForeclosures = foreclosures.length ?? 0
  const foreclosureDatesMinMax = useMemo(() => {
    let oldestForeclosure = ''
    let newestForeclosure = ''
    for (let i = 0; i < foreclosures.length; i++) {
      const d = select.foreclosureDateRaw(foreclosures[i]) // use YYYYMMDD for sorting
      if (typeof d === 'string' && d.length > 0 && parseInt(d) > 0) {
        if (oldestForeclosure === '' || d < oldestForeclosure) oldestForeclosure = d
        if (newestForeclosure === '' || d > newestForeclosure) newestForeclosure = d
      }
    }
    return {
      oldest: reformatDate(oldestForeclosure),
      newest: reformatDate(newestForeclosure),
    }
  }, [foreclosures, select])

  /* Translation calls with t() must occur after translationReady is true */
  /* Conditional returns must take place after all hooks have been called */
  if (!translationReady) return null
  if (!foreclosures) return null

  const PreforeclosureSection = () => {

    /* Wait for data to become available */
    if (isNull(foreclosures) || !isLoggedIn) {
      return null
    }

    return (
      <PreForeclosureList
        slice={`${neighborhoodName}-foreclosures`}
        title={t('Foreclosures')}
        subTitle={
          <>
            {t(
              'We know about {{numForeclosures}} pre-foreclosures for {{neighborhoodName}}, which range from {{oldest}} to {{newest}}',
              {
                neighborhoodName,
                numForeclosures: numForeclosures,
                oldest: foreclosureDatesMinMax.oldest,
                newest: foreclosureDatesMinMax.newest,
                context: numForeclosures === 0
                  ? 'zero'
                  : numForeclosures === 1
                    ? 'one'
                    : '',
              }
            )}
          </>
        }
        noDataMessage={t('No pre-foreclosures found for this neighborhood.')}
        aboutData={{
          source: '',
          href: '/methodology',
          notes: t(`Pre-foreclosure records are matched to residential parcel
            records and property ownership records based on APN identifiers.
            They are identified as such based on a third party provider's
            identifying rules like late mortgage or tax payment.`)
        }}
        paginationPageSize={5}
        hideUnpopulatedColumns={true}
        csvFileName={t('foreclosures at {{neighborhoodName}}', {
          neighborhoodName,
        })}
      />
    )
  }

  return (
    <div>
      <Row>
        <Col xs={12}>
          <DataPage
            fetchArray={[
              ...(isLoggedIn
                ? [
                  {
                    slice: `${neighborhoodName}-foreclosures`,
                    url: `/api/neighborhood/${encodeURIComponent(neighborhoodName)}/foreclosures`,
                  },
                  // This would need to be fetched in a Data or DataTable component instead of DataPage:
                  // {
                  //   slice: `${neighborhoodName}-foreclosures-page-${pageNumber}`,
                  //   url: `/api/neighborhood/${encodeURIComponent(neighborhoodName)}/foreclosures/${pageNumber}`,
                  // }
                ]
                : []),
              //{ slice: 'about-foreclosures', url: '/api/about/foreclosures' },
            ]}
            isDebugEnabled={false}>
            <Row justifyContent='center'>
              <Col xs={10} sm={8} style={columnStyle}>
                {
                  isLoggedIn
                    ? (
                      <>
                        <PageTitle
                          text={`Pre-foreclosures for the ${neighborhoodName} neighborhood`}
                          style={{ marginTop: '.15rem' }}
                        />
                        <PreforeclosureSection />
                      </>
                    )
                    : <NoPage />
                }
              </Col>
            </Row>
          </DataPage>
        </Col>
      </Row>
    </div>
  )
}

export default memo(Preforeclosures)
