import  { useState, useEffect, memo } from 'react'
import { useSelector } from 'react-redux'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import DataPage, { selectRecords, selectRecord, isEmpty } from '../components/DataPage/DataPage'
import DataTable from '../components/DataTable/DataTable'
import { Row, Col } from '../components/Grid'
import { commaSeparatedBolded } from '../utils/stringUtils'
import NetworkList from '../components/NetworkList'
import PropertyList from '../components/PropertyList'
import PageTitle from '../components/PageTitle'
import i18n from '../i18n'
import D3Graph from '../components/Graph/D3Graph'
import { renderOwnerLink, renderParcelLink } from '../utils/tableUtils'
import { useParams } from 'react-router-dom'
import { config } from '../config/config'

const columnStyle = /** @type {const} */ ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})


/**
 * NetworkPage: presents content for an ownership network and its connections
*/
const NetworkPage = () => {

  /* Set up API data fields, values, and selectors */
  const select = config.api.response.networkPage.selectors
  const field = config.api.response.networkPage.fields
  const value = config.api.response.networkPage.values

  /* Get the id path parameter from the URL bar */
  const params = useParams()
  const { id } = params ?? {}
  const networkId = id

  /* Set hooks to use Redux global state */
  const details = useSelector(selectRecord(`${networkId}-details`))
  const graph = useSelector(selectRecords(`${networkId}-graph`))

  /* Set up the react-i18next translation engine */
  const { t, ready: translationReady } = useTranslation(
    'NetworkPage', /* i18next-parser needs string here */
    { useSuspense: false, i18n }
  )

  /* Local state */
  const [shouldGetGraph, setShouldGetGraph] = useState(false)

  useEffect(() => {
    if (!isEmpty(details)) {
      if (select.numBusinesses(details) < 500) { setShouldGetGraph(true) }
    }
    // eslint-disable-next-line
  }, [details])

  /* Translation calls with t() must occur after translationReady is true */
  /* Conditional returns must take place after all hooks have been called */
  if (!translationReady) { return null }

  const NetworkDetails = () => {

    /* Set hooks to use Redux global state */
    const networkNodes = useSelector(selectRecords(`${networkId}-nodes`))
    const businessSourceData = useSelector(selectRecord('about-business'))

    /* Wait for data to become available */
    if (isEmpty(networkNodes)) { return null }

    /* Dynamic text */
    const businessSourceText = Object.values(businessSourceData).map(sourceData =>
      select.sourceName(sourceData) + ' (as of ' + select.sourceDate(sourceData) + ')').join(', ')

    return (
      <>
        <Typography variant='h2'>
          {t('Businesses, People, and Addresses in this Ownership Network')}
        </Typography>
        <Typography variant='body1'>
          {t('In total, there are {{count}} businesses', {
            count: select.numBusinesses(details)
          })}
          {t(', {{count}} business owners', {
            count: select.numOwners(details)
          })}
          {t(' and {{count}} business or property owner addresses in this ownership network.', {
            count: select.numAddresses(details)
          })}
        </Typography>
        <DataTable
          rows={networkNodes}
          columns={[
            {
              field: field.nodeName,
              headerName: 'Name',
              flex: 3,
              renderCell: p => {
                if (select.nodeType(p.row) === value.addressUnit) {
                  return renderParcelLink(
                    select.nodeParcelId(p.row),
                    select.nodeParcelAddress(p.row)
                  )
                }
                else {
                  return renderOwnerLink(
                    select.nodeOwnerName(p.row),
                    select.nodeBusinessType(p.row)
                  )
                }
              }
            },
            { 
              field: field.readableType,
              headerName: 'Type',
              flex: 1.5,
              type: 'string'
            },
          ]}
          title={t('Ownership Network')}
          isDebugEnabled={false}
          aboutData={{
            source: businessSourceText,
            href: '/methodology',
            notes: t('Due to inexact name matches, some businesses may be listed as business owners')
          }}
          noDataMessage={t('Ownership network data not found')}
          sortModel={[
            { field: field.entity, sort: 'asc' },
            { field: field.type, sort: 'asc' }
          ]}
          paginationPageSize={10}
          csvFileName={t('Businesses, People, and Addresses in this Ownership Network with ID {{networkId}}',
            { networkId: networkId })}
        />
      </>
    )
  }

  const PropertyPortfolio = () => {

    /* Set hooks to use Redux global state */
    const assessorSourceData = useSelector(selectRecord('about-assessor'))

    /* Wait for data to become available */
    // neither of the source data fields appear to be rendering correctly
    // if (isEmpty(assessorSourceData)) { return null }

    /* Dynamic text */
    const assessorSourceText = Object.values(assessorSourceData).map(sourceData =>
      select.sourceName(sourceData) + ' (as of ' + select.sourceDate(sourceData) + ')').join(', ')

    return (
      <>
        <PropertyList
          slice={`${networkId}-portfolio`}
          title={t('Property Portfolio')}
          subTitle={
            <>
              {t('We have determined that the following buildings may be owned by this ownership network.')}
            </>
          }
          noDataMessage={t('No portfolio properties found for this parcel.')}
          aboutData={{
            source: assessorSourceText,
            href: '/methodology',
            notes: t(
              `Property portfolios are created by identifying properties with the
              same mailing address(es). We associate properties with business ownership
              networks through either the name listed as the property owner (if it is a
              business) and through the property mailing addresses that are also business
              office addresses. Some business networks have multiple addresses. In counting
              the number of evictions for a property, Ellis Act evictions are counted as
              separate evictions for each unit.`
            )
          }}
          paginationPageSize={5}
          csvFileName={t('Properties for Ownership Network with ID {{ networkId }}', { networkId })}
        />
      </>
    )
  }

  return (
    <Row>
      <Col xs={12}>
        <DataPage fetchArray={[
          { slice: `${networkId}-details`, url: `/api/network/${encodeURIComponent(networkId)}/details` },
          { slice: `${networkId}-portfolio`, url: `/api/network/${encodeURIComponent(networkId)}/portfolio` },
          { slice: `${networkId}-network`, url: `/api/network/${encodeURIComponent(networkId)}/relatedNetworks` },
          { slice: `${networkId}-nodes`, url: `/api/network/${encodeURIComponent(networkId)}/nodes` },
          ...(shouldGetGraph ? [{ slice: `${networkId}-graph`, url: `/api/network/${encodeURIComponent(networkId)}/graph` }] : []),
          { slice: 'about-business', url: '/api/about/business' },
          { slice: 'about-assessor', url: '/api/about/assessor' },
        ]} isDebugEnabled={false}>
          {
            /* If the graph is fetchable but not yet fetched,
             * do not show the rest of the content, to avoid a layout shift
             * */
            /* TODO: create a DataGraph that can fetch data itself and initialize with a skeleton loader that takes up the same amount of space */
            !isEmpty(details) && select.numBusinesses(details) < 500 && isEmpty(graph) ? null : 
              <Row justifyContent='center'>
                <Col xs={10} sm={8} style={columnStyle}>
                  <PageTitle text={t('Ownership Network')} />
                  <Typography variant='body1'>
                    {t('Containing')}{' '}
                    {commaSeparatedBolded([
                      select.topOwnerName(details),
                      select.topBusinessName(details),
                      select.topAddressReadable(details)
                    ])}{'.'}
                  </Typography>
                  <Typography variant='h2'>{t('Network Connections')}</Typography>
                  { // conditionally render the graph and show an alternative message if it's not rendered
                    //    using a second <Data /> component here to avoid loading the things in <DataPage /> twice
                    shouldGetGraph ?
                      <>
                        <Typography variant='body1'>
                          {t('Below is the full set of network connections within this ownership network.')}
                        </Typography>
                        <br />
                        <D3Graph graph={graph} />
                      </> :
                      <Typography variant='body1'>
                        {t('Large ownership networks are currently unable to be shown.')}
                      </Typography>
                  }
                  <NetworkDetails />
                  <PropertyPortfolio />
                  <NetworkList
                    slice={`${networkId}-network`}
                    noDataMessage={t('No related networks found.')}
                  />
                </Col>
              </Row>
          }
        </DataPage>
      </Col>
    </Row>
  )

}

export default memo(NetworkPage)
